<template>
  <div class="v_task_issue_dian_fu">
    <!-- 头部 -->
    <div class="v-task-issue-head">
      <div class="v-task-issue-head-stepbox g-flex-wrap g-flex-align-center g-flex-justify-center">
        <el-steps :space="200" :active="active" finish-status="success">
          <el-step title="选择类型"></el-step>
          <el-step title="填写信息"></el-step>
          <el-step title="支付押金"></el-step>
          <el-step title="发布成功"></el-step>
        </el-steps>

        <div class="v-task-issue-head-btn g-pointer g-flex-align-center g-flex-justify-center" @click="$router.push({ path: '/issuetask3/taskstoreliulanlist'})">
          <i class="iconfont iconcaidan"></i>
          <span>选择最近发布的活动</span>
        </div>
      </div>

      <div class="v-task-issue-head-tips g-flex-align-start">
        <i class="iconfont icongantanhao"></i>
        <div class="v-task-issue-head-tips-list">
          <p class="v-task-issue-head-tips-list-item">1、请注意控制好商品的搜索转化率！不得超过行业平均转化率1.5倍，转化率过高，易导致产品降权！</p>
          <p class="v-task-issue-head-tips-list-item">2、为了避免您的损失，垫付任务中，平台建议关闭或者降低淘宝客/京挑客佣金，因很多浏览器和APP商店自带劫持淘客链接劫持功能，因此产生佣金及纠纷概不负责</p>
        </div>
      </div>
    </div>

    <!-- 中间部分 -->
    <div class="v-task-issue-middle">
      <!-- 选择类型 -->
      <task-issue-liu-lan-step-one v-show="active === 1" :platformList="platformList" :allStoreList="allStoreList" :form="form"/>

      <!-- 填写信息 -->
      <task-issue-liu-lan-step-two v-show="active === 2" 
      ref="TaskIssueLiuLanStepTwo"
      :form="form" 
      :taskTypeList="taskTypeList" 
      :areaCities="areaCities" 
      :huobiList="huobiList" 
      :liulanList="liulanList"
      :mainLiulanList="mainLiulanList"
      :payList="payList"
      :xinYuLevelList="xinYuLevelList"
      :creditPayPrice="creditPayPrice"
      :taskPassPrice="taskPassPrice"
      :sexPrice="sexPrice"
      :provincePrice="provincePrice"
      :clockList="clockList"
      :clockEndList="clockEndList"
      :chineClockList="chineClockList"
      :freePrice="freePrice"
      :agePrice="agePrice"
      :addGoodsPrice="addGoodsPrice"
      :totalTaskNum="totalTaskNum"
      :timeObjTaskNum="timeObjTaskNum"
      :xdsjList="xdsjList"
      />

      <!-- 支付押金 -->
      <task-issue-liu-lan-step-three v-show="active === 3" 
      :servicePrice="servicePrice" 
      :BaseMoney="BaseMoney" 
      :BenJinMoney="BenJinMoney"
      :ZengZhiMoney="ZengZhiMoney"
      :totalTaskNum="totalTaskNum"
      :timeObjTaskNum="timeObjTaskNum"
      :form="form"
      />


      <!-- 发布成功 -->
      <task-issue-liu-lan-step-four :taskSucStaus="taskSucStaus" v-show="active === 4" ref="TaskIssueLiuLanStepFour"/>

    </div>
    

    
  

    <div class="v-task-issue-bottom">
      <el-button style="margin-top: 12px;" type="info" size="medium" @click="previousClick" v-show="!(active === 1 || active===4)">上一步</el-button>
      <el-button style="margin-top: 12px;" type="primary" size="medium" @click="nextClick" v-show="active === 1 || active === 2">下一步</el-button>
      <el-button style="margin-top: 12px;" type="primary" size="medium" @click="sureClick" v-if="active === 3 && auth.taskissue">确认发布</el-button>
    </div>

  </div>
</template>

<script>
  import { apiGetTaskItemInfo } from '@/utils/api.js'
  import TaskIssueLiuLanStepOne from './TaskIssueLiuLanStepOne.vue'
  import TaskIssueLiuLanStepTwo from './TaskIssueLiuLanStepTwo.vue'
  import TaskIssueLiuLanStepThree from './TaskIssueLiuLanStepThree.vue'
  import TaskIssueLiuLanStepFour from './TaskIssueLiuLanStepFour.vue'
  import { apiGetTaskConfig, apiReleaseTask, apiGetBasePrice, apiGetUserBalance } from '@/utils/api.js'
  export default {
    components: { TaskIssueLiuLanStepOne, TaskIssueLiuLanStepTwo, TaskIssueLiuLanStepThree, TaskIssueLiuLanStepFour },
    data() {
      return {
        auth:{
          taskissue: this.$global.ApiList.includes(45), //发布垫付任务
        },
        // 编辑进来 任务id
        taskId: '',

        // 编辑数据是否取完了
        isGetDataAlready: false,

        active: 1,

        // 任务发布成功状态 10 未支付（要充值）  20 等待后台审核 30 发布成功
        taskSucStaus: 10,
        
        // 淘宝基础服务费 不做prop
        tbServicePrice: 0,
        // 京东基础服务费 不做prop
        jdServicePrice: 0,
        // 拼多多基础服务费 不做prop
        pddServicePrice: 0,


        // 商家发布任务的基础佣金是多少 阶梯式的佣金 需要向后台获取
        BaseMoney: 0,
        
        // 平台基础服务费 现在做成针对每个店铺配置不同平台服务费 需要向后台获取
        servicePrice: 0,

        addgoodsFlag: true,

        // 商城类型 1淘宝 2京东 3拼多多
        platformList: [{ title: '', id: '', price: '', platformPlayList: [] }],

        allStoreList: [{ id: '', platform_id: '', title: ''}], //所有店铺列表

        // 省
        areaCities: [
          { area: '华东', checkAll: false, checkedCities: [], immediate: false, cities: [{ name: '上海市', code: '310000' }, { name: '江苏省', code: '320000' }, { name: '浙江省', code: '330000' }, { name: '安徽省', code: '340000' }, { name: '江西省', code: '360000' }]},
          { area: '华北', checkAll: false, checkedCities: [], immediate: false, cities: [{ name: '北京市', code: '110000' }, { name: '天津市', code: '120000' }, { name: '山西省', code: '140000' }, { name: ' 山东省', code: '370000' }, { name: '河北省', code: '130000' }, { name: '内蒙古', code: '150000' }]},
          { area: '华中', checkAll: false, checkedCities: [],immediate: false, cities: [{ name: '湖南省', code: '430000' }, { name: '湖北省', code: '420000' }, { name: '河南省', code: '410000' } ]},
          { area: '华南', checkAll: false, checkedCities: [], immediate: false, cities: [{ name: '广东省', code: '440000' }, { name: '广西省', code: '450000' }, { name: '福建省', code: '350000' }, { name: '海南省', code: '460000' } ]},
          { area: '东北', checkAll: false, checkedCities: [], immediate: false, cities: [{ name: '辽宁省', code: '210000' }, { name: '吉林省', code: '220000' }, { name: '黑龙江省', code: '230000' } ]},
          { area: '西北', checkAll: false, checkedCities: [], immediate: false, cities: [{ name: '陕西省', code: '610000' }, { name: '新疆', code: '650000' }, { name: '甘肃省', code: '620000' }, { name: '宁夏', code: '640000' }, { name: '青海省', code: '630000' } ]},
          { area: '西南', checkAll: false, checkedCities: [], immediate: false, cities: [{ name: '重庆市', code: '500000' }, { name: '云南省', code: '530000' }, { name: '贵州省', code: '520000' }, { name: '西藏', code: '540000' }, { name: '四川省', code: '510000' } ]},
        ],

        form: {
          platform_id: '', //商城id
          platform_play_id: '', // 任务类型id
          platform_play_type: '', //任务类型是啥 0=未知；1=普通垫付；2=预售垫付；3=叠词回访；4=竞品流量劫持；5=高级引流；6=特别垫付；7=开团垫付；8=直播垫付；
          entrance: '', //活动入口
          user_shop_id: '', //店铺id
          url: '', //商品链接
          title: '', //商品标题
          img: [], // // 宝贝示例图 商品示例图，最多3张
          search_price: '',//搜索展示价
          actual_price: '',//宝贝下单价
          entrance_remark: '', // 入口描述
          entrance_store: '', //进店链接
          tkl: '',  // 淘口令
          entrance_pic: [], //步骤截图
          search_sort: '综合排序',  // 搜索排序方式  综合排序;销量排序;直通车
          search_remark: '', //搜索备注
          is_free: 1, //1-包邮 0-不包邮
          task_type: 0,//搜索关键词任务类型 10 普通 11 指定文字 12图文
          search_keyword: [ { keyword: '', num: '', img: [], reviews: '', size: '' }], // 搜索关键词列表
          buy_num: '', //拍下件数
          goods: [], // 附加商品列表  { url: '', img: '', price: '', num: '' }
          detail_keyword: '', //详情页关键字
          // 货比
          huobi: '',
          // 浏览副宝贝
          liulan: '',

          // 浏览主宝贝
          main_liulan: [],

          // 支付方式
          pay_type: '',

          // 操作中截图 0 不截图 1 截图
          screenshot: 0,

          // 确认收货截图  0 不截图 1 截图
          screenshot_arrival: 0,

          // 特殊要求
          explain:'',
          // 特殊要求示例图
          explain_img: [],

          // 加赏佣金
          reward: '',

          // 商家审核
          task_pass: 0,

          // 发布方式
          time_type: 1,

          // 任务发布时间
          timeVal: new Date(new Date().getTime()),

          get time_val() {
            return parseInt(this.timeVal.getTime() / 1000) 
          },

          // 发布时间的对象
          time_obj: {},

          // 任务发布间隔
          time_interval: 5,

          // 发布模式
          time_model: 1,

          // 开始时间
          startDate: new Date(),

          // 传给后台的开始时间
          get start_date() {
            let year = new Date(this.startDate).getFullYear()
            let month = new Date(this.startDate).getMonth() + 1
            let day = new Date(this.startDate).getDate() 
            return `${year}-${month}-${day}`
          },
          // 开始时刻
          startTime: new Date().getHours(),
          // 结束时刻
          endTime: 23,
          // 任务回收时间
          close_time: 0,
          // 信誉要求
          xinyu: 1,
           // 开通花呗 0不需要 1需要
          credit_pay: 0,
          // 性别限制
          sex: 0,
          // 年龄限制
          age: 0,
          // 地区限制
          province_code: [],

          // 店铺申请限制
          shop_limit: 1,

          // 发布申请限制
          account_limit: 0,
          // 下单时间（预售任务才有的）
          xdsj: '',





          // 直播的时候进店类型
          zhiboType:1,

          // 选择找到主播方式
          findZhubo: 1,

          // 主播名字或者链接
          zhuboName: '',

          // 直播二维码
          zhuboQRcode: '',

          // 步骤截图
          stepImgList: [],

          // 是否关注主播
          guanZhuzhubo: 1,

        },

        // 第二步填写信息 任务类型
        taskTypeList: [
          {  "id": 10,
            "code": "taskTypeList",
            "title": "普通浏览任务",
            "price": "0.00",
            "remark": "试客找到商品后进行浏览"
          }
        ],

        // 货比列表
        huobiList: [],

        // 浏览副宝贝
        liulanList: [],

        // 主宝贝浏览
        mainLiulanList: [],

        // 付款方式
        payList: [
          { name: '禁用花呗/信用卡/淘金币/白条',  value: '禁用花呗/信用卡/淘金币/白条' },
          { name: '允许使用花呗/白条/信用卡',  value: '允许使用花呗/白条/信用卡' },
          { name: '必须使用花呗/白条/信用卡付款',  value: '必须使用花呗/白条/信用卡付款' },
        ],

        // 信誉要求列表
        xinYuLevelList: [],

        // 下单时间（预售任务才有的）
        xdsjList: [],

        // 附加商品价钱
        addGoodsPrice: [{ price: ''}],
        // 开通花呗价钱
        creditPayPrice: [{ price: ''}],
        // 性别价钱
        sexPrice: [{ price: ''}],
        // 商家审核价钱
        taskPassPrice: [{ price: ''}],
        // 年龄价钱
        agePrice: [{ price: ''}],
        //地区价钱
        provincePrice: [{ price: ''}],
        // 邮费价钱
        freePrice: [{ price: ''}],
        // 开始时刻 结束时刻
        clockList: [
          { name: '请选择', value: -1 },
          { name: '00:00', value: 0 },
          { name: '01:00', value: 1 },
          { name: '02:00', value: 2 },
          { name: '03:00', value: 3 },
          { name: '04:00', value: 4 },
          { name: '05:00', value: 5 },
          { name: '06:00', value: 6 },
          { name: '07:00', value: 7 },
          { name: '08:00', value: 8 },
          { name: '09:00', value: 9 },
          { name: '10:00', value: 10 },
          { name: '11:00', value: 11 },
          { name: '12:00', value: 12 },
          { name: '13:00', value: 13 },
          { name: '14:00', value: 14 },
          { name: '15:00', value: 15 },
          { name: '16:00', value: 16 },
          { name: '17:00', value: 17 },
          { name: '18:00', value: 18 },
          { name: '19:00', value: 19 },
          { name: '20:00', value: 20 },
          { name: '21:00', value: 21 },
          { name: '22:00', value: 22 },
          { name: '23:00', value: 23 },
        ],

        // 结束时刻
        clockEndList: [
          { name: '请选择', value: -1 },
          { name: '01:00', value: 0 },
          { name: '02:00', value: 1 },
          { name: '03:00', value: 2 },
          { name: '04:00', value: 3 },
          { name: '05:00', value: 4 },
          { name: '06:00', value: 5 },
          { name: '07:00', value: 6 },
          { name: '08:00', value: 7 },
          { name: '09:00', value: 8 },
          { name: '10:00', value: 9 },
          { name: '11:00', value: 10 },
          { name: '12:00', value: 11 },
          { name: '13:00', value: 12 },
          { name: '14:00', value: 13 },
          { name: '15:00', value: 14 },
          { name: '16:00', value: 15 },
          { name: '17:00', value: 16 },
          { name: '18:00', value: 17 },
          { name: '19:00', value: 18 },
          { name: '20:00', value: 19 },
          { name: '21:00', value: 20 },
          { name: '22:00', value: 21 },
          { name: '23:00', value: 22 },
          { name: '24:00', value: 23 },
        ],

        // 下面的input框
        chineClockList: [
          { name: '0点-1点', key: 0, value: '' },
          { name: '1点-2点', key: 1, value: '' },
          { name: '2点-3点', key: 2, value: '' },
          { name: '3点-4点', key: 3, value: '' },
          { name: '4点-5点', key: 4, value: '' },
          { name: '5点-6点', key: 5, value: '' },
          { name: '6点-7点', key: 6, value: '' },
          { name: '7点-8点', key: 7, value: '' },
          { name: '8点-9点', key: 8, value: '' },
          { name: '9点-10点', key: 9, value: '' },
          { name: '10点-11点', key: 10, value: '' },
          { name: '11点-12点', key: 11, value: '' },
          { name: '12点-13点', key: 12, value: '' },
          { name: '13点-14点', key: 13, value: '' },
          { name: '14点-15点', key: 14, value: '' },
          { name: '15点-16点', key: 15, value: '' },
          { name: '16点-17点', key: 16, value: '' },
          { name: '17点-18点', key: 17, value: '' },
          { name: '18点-19点', key: 18, value: '' },
          { name: '19点-20点', key: 19, value: '' },
          { name: '20点-21点', key: 20, value: '' },
          { name: '21点-22点', key: 21, value: '' },
          { name: '22点-23点', key: 22, value: '' },
          { name: '23点-24点', key: 23, value: '' },
        ],

        // 淘宝附加商品（不props）
        TaoBaoAddGoodsList: [],
        // 京东附加商品（不props）
        JDAddGoodsList: [],
        // 拼多多附加商品（不props）
        PDDAddGoodsList: [],
        // 京东信誉等级（不props）
        JDXinYuLevelList: [],
        // 淘宝信誉等级（不props）
        TaoBaoXinYuLevelList: []
      }
    },
    async created() {
      this.taskId = this.$route.query.id
      console.log('taskId', this.taskId)
      await this.apiGetTaskConfigHandel()
      if(this.taskId) await this.apiGetTaskItemInfoHandel()
    },
    methods: {
      // 编辑进来获取详情
      async apiGetTaskItemInfoHandel() {
        let loading = this.$loading({ text: '拼命加载中...' })
        const { success, data } = await apiGetTaskItemInfo({ id: this.taskId }).catch(() => loading.close())
        loading.close()
        if(!success) return
        console.log(data)
        this.form.platform_id = data.data.platform_id
        // 如果是淘宝
        if(this.form.platform_id === 1) {
          this.xinYuLevelList = this.TaoBaoXinYuLevelList
          this.addGoodsPrice = this.TaoBaoAddGoodsList
        }
        // 如果是京东
        else if(this.form.platform_id === 2) {
          this.xinYuLevelList = this.JDXinYuLevelList
          this.addGoodsPrice = this.JDAddGoodsList
        }
        // 如果是拼多多
        else if(this.form.platform_id === 3) {
          this.addGoodsPrice = this.PDDAddGoodsList
          this.xinYuLevelList = []
        }
        this.form.user_shop_id = data.data.user_shop_id
        this.form.platform_play_id = data.data.platform_play_id
        let platformPlayList = this.platformList.find((item) => item.id === this.form.platform_id).platformPlayList
        this.form.platform_play_type = platformPlayList.find(item => item.id === this.form.platform_play_id).type
        console.log(this.form.platform_play_type)
        this.form.entrance = data.data.entrance
        this.form.task_type = data.data.task_type
        this.form.huobi = data.data.huobi
        this.form.liulan = data.data.liulan
        this.form.xdsj = data.data.xdsj
        this.form.xinyu = data.data.xinyu
        this.form.url = data.data.url
        this.form.title = data.data.title
        this.form.img = data.data.img
        this.form.search_price = data.data.search_price
        this.form.actual_price = data.data.actual_price
        this.form.entrance_remark = data.data.entrance_remark
        this.form.tkl = data.data.tkl
        this.form.entrance_pic = data.data.entrance_pic
        this.form.search_sort = data.data.search_sort
        this.form.search_remark = data.data.search_remark
        this.form.is_free = data.data.is_free
        this.form.buy_num = data.data.buy_num
        this.form.task_type = data.data.task_type
        if(data.data.search_keyword.length) this.form.search_keyword = data.data.search_keyword
        this.form.goods = data.data.goods
        this.form.detail_keyword = data.data.detail_keyword
        this.form.huobi = data.data.huobi
        this.form.liulan = data.data.liulan    
        this.form.main_liulan = data.data.main_liulan.map(item => Number(item))     
        this.form.pay_type = data.data.pay_type    
        this.form.screenshot = Number(data.data.screenshot)  
        this.form.screenshot_arrival = Number(data.data.screenshot_arrival)  
        this.form.explain = data.data.explain 
        this.form.explain_img = data.data.explain_img
        this.form.reward = Number(data.data.reward)
        this.form.time_type = Number(data.data.time_type)
        this.form.time_obj = data.data.time_obj
        this.form.time_interval = Number(data.data.time_interval)
        this.form.time_model = Number(data.data.time_model)
        if(data.data.startTime != -1) {
          this.form.startTime = data.data.startTime
          this.form.endTime = data.data.endTime
        }
        this.form.endTime = data.data.endTime
        this.form.close_time = data.data.close_time
        this.form.credit_pay = data.data.credit_pay
        this.form.sex = data.data.sex
        this.form.age = data.data.age
        this.form.province_code = data.data.province_code
        this.form.shop_limit = data.data.shop_limit
        this.form.account_limit = data.data.account_limit
        this.form.task_pass = data.data.task_pass
        this.form.entrance_store = data.data.entrance_store
        if(this.time_obj !== {}) this.timeObjByEdit()
        if(this.form.province_code.length) this.provinceCheckByCode()
        this.isGetDataAlready = true
      },

      // 获取配置
      async apiGetTaskConfigHandel() {
        const { success, data } = await apiGetTaskConfig({ type: 1 })
        if(!success) return
        // 三个平台的基础服务费
        this.tbServicePrice =  Number(data.data.task.service_price1)
        this.jdServicePrice =  Number(data.data.task.service_price2)
        this.pddServicePrice =  Number(data.data.task.service_price3)
        
        // this.servicePrice = Number(data.data.service_price)

        this.platformList = data.data.platformList
       
        this.allStoreList = this.allStoreList.concat(data.data.storeList)
        // this.taskTypeList = data.data.taskTypeList
        
        this.huobiList = data.data.huobi
        this.liulanList = data.data.liulan
        this.mainLiulanList = data.data.main_liulan
        
        this.xdsjList = data.data.xdsj
        this.creditPayPrice = data.data.credit_pay
        this.sexPrice = data.data.sex
        this.taskPassPrice = data.data.task_pass
        this.provincePrice = data.data.province
        this.freePrice = data.data.free
        this.agePrice = data.data.age
        
        // 京东信誉等级 不做prop
        this.JDXinYuLevelList = data.data.jd_xinyu
        this.TaoBaoXinYuLevelList = data.data.xinyu
        // 淘宝附加商品 不做prop
        this.TaoBaoAddGoodsList = data.data.tb_fjsp
        this.JDAddGoodsList = data.data.jd_fjsp
        this.PDDAddGoodsList = data.data.pdd_fjsp

        // 如果是编辑 就不要处理第一项了
        if(!this.taskId) {
          // 信誉要求比较特殊 要根据点了那个平台再决定 只有再新增的时候去取第一个
          this.xinYuLevelList = data.data.xinyu
          this.form.platform_id = this.platformList[0].id
          // this.form.task_type = data.data.taskTypeList[0].id
          this.form.task_type = this.taskTypeList[0].id
          this.form.huobi = data.data.huobi[0].id
          this.form.liulan = data.data.liulan[0].id
          this.form.xinyu = data.data.xinyu[0].id
          this.form.xdsj = data.data.xdsj[0].id
          this.isGetDataAlready = true
        }
        console.log(data)
      },

      // 点击上一步
      previousClick() {
        if (this.active-- === 1) return this.active = 1
      },

      // 点击下一步
      async nextClick() {
        // 第一步
        if(this.active === 1) {
          if(!this.form.platform_id) return this.$message.error('请选择商城')
          if(!this.form.user_shop_id) return this.$message.error('请选择店铺')
          if(!this.form.platform_play_id) return this.$message.error('请选择任务类型')
          let platformPlayList = this.platformList.find((item) => item.id === this.form.platform_id).platformPlayList
          let extend = platformPlayList.find(item => item.id === this.form.platform_play_id).extend
          if(extend.length != 0 && !this.form.entrance) return this.$message.error('请选择活动单类型')
          this.form.pay_type = this.payList[0].value
          // 如果是淘宝
          if(this.form.platform_id === 1) {
            this.xinYuLevelList = this.TaoBaoXinYuLevelList
            this.form.xinyu = this.xinYuLevelList[0].id
            this.addGoodsPrice = this.TaoBaoAddGoodsList
          }
          // 如果是京东
          else if(this.form.platform_id === 2) {
            this.xinYuLevelList = this.JDXinYuLevelList
            this.form.xinyu = this.xinYuLevelList[0].id
            this.addGoodsPrice = this.JDAddGoodsList
          }
          // 如果是拼多多
          else if(this.form.platform_id === 3) {
            this.xinYuLevelList = []
            this.addGoodsPrice = this.PDDAddGoodsList
            this.form.xinyu = 0
          }
        }
        // 第二步
        else if(this.active === 2) {
          this.form.province_code = this.provinceCodeDetail()
          console.log(this.form)
          // 是淘宝淘宝
          // if(this.form.platform_id === 1) {
            if(!this.form.url) return this.$message.error('请填写主商品链接')
            if(!this.form.title) return this.$message.error('请填写主商品标题')
            if(!this.form.img.length) return this.$message.error('请上传宝贝示例图')
            if(!this.form.search_price) return this.$message.error('请填写搜索展示价')
            // if(!this.form.actual_price) return this.$message.error('请填写宝贝下单价')
           
            if(this.form.entrance === '淘口令') {
              this.form.entrance_store = ''
              if(!this.form.tkl) return this.$message.error('请填写淘口令')
            }
            if(this.form.entrance === '其他') {
              this.form.tkl = ''
              if(!this.form.entrance_store)  return this.$message.error('请填写进店链接')
            }
            if(this.form.entrance !== '淘口令' && this.form.entrance !== '其他') {
              this.form.entrance_store = ''
              this.form.tkl = ''
            }

            // 附加商品
            if(this.form.goods.length > 0) {
              this.form.goods.forEach(item => {
                if(!item.url || !item.img || !item.price || !item.num) this.addgoodsFlag = false
                if(!item.url) return this.$message.error('请填写附加商品链接')
                if(!item.img) return this.$message.error('请填写附加商品图')
                // if(!item.price) return this.$message.error('请填写附加商品下单价')
                // if(!item.num) return this.$message.error('请填写附加商品下单数')
              })
              if(!this.addgoodsFlag) return this.addgoodsFlag = true
            }

            //  // 有入口的时候 搜索关键词 和 任务数量
            if(this.form.entrance === '淘口令' || this.form.entrance === '其他') {
              if(this.form.task_type == 10) {
                this.form.search_keyword.forEach(item => {
                  item.keyword = ''
                  item.img = []
                  item.size = ''
                  item.reviews = ''
                  // 如果是其他链接为必填项
                  if(!Number(item.num)) this.addgoodsFlag = false
                  if(!Number(item.num)) return this.$message.error('请填写您要发布的任务单数')
                })
              }else if(this.form.task_type == 11) {
                this.form.search_keyword.forEach(item => {
                  item.keyword = ''
                  item.img = []
                  item.size = ''
                  if(!item.reviews) this.addgoodsFlag = false
                  if(!item.reviews) return this.$message.error('请填写指定文字的好评内容')
                })
              }else if(this.form.task_type == 12) {
                this.form.search_keyword.forEach(item => {
                  item.keyword = ''
                  if(!item.size || !item.reviews || !item.img.length) this.addgoodsFlag = false
                  if(!item.size) return this.$message.error('请填写指定图片好评的规格')
                  if(!item.reviews) return this.$message.error('请填写指定图片好评的内容')
                  if(!item.img.length) return this.$message.error('请上传指定图片好评的图片')
                })
              }
            }else {
              this.form.search_keyword.forEach(item => {
                if(!item.keyword) this.addgoodsFlag = false
                if(!item.keyword) return this.$message.error('请填写搜索关键词')
                if(this.form.task_type == 10) {
                  this.form.search_keyword.forEach(item => {
                    item.img = []
                    item.size = ''
                    item.reviews = ''
                    // 如果是其他链接为必填项
                    if(!Number(item.num)) this.addgoodsFlag = false
                    if(!Number(item.num)) return this.$message.error('请填写您要发布的任务单数')
                  })
                }else if(this.form.task_type == 11) {
                  this.form.search_keyword.forEach(item => {
                    item.img = []
                    item.size = ''
                    if(!item.reviews) this.addgoodsFlag = false
                    if(!item.reviews) return this.$message.error('请填写指定文字的好评内容')
                  })
                }else if(this.form.task_type == 12) {
                  this.form.search_keyword.forEach(item => {
                    if(!item.size || !item.reviews || !item.img.length) this.addgoodsFlag = false
                    if(!item.size) return this.$message.error('请填写指定图片好评的规格')
                    if(!item.reviews) return this.$message.error('请填写指定图片好评的内容')
                    if(!item.img.length) return this.$message.error('请上传指定图片好评的图片')
                  })
                }
              })
            } 
            
            if(!this.addgoodsFlag) return this.addgoodsFlag = true

            // 详情页验证关键词
            if(!this.form.detail_keyword) return this.$message.error('请填写详情页验证关键词')
            if(this.form.detail_keyword.length < 2 || this.form.detail_keyword.length > 10) return this.$message.error('详情页验证关键词最少2个字，最多10个字')
            var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
            if ((pattern.test(this.form.detail_keyword))) return this.$message.error('详情页验证关键词不能有特殊符号')
            

            // 发布时间单数处理
            if(this.form.time_type == 2 && this.form.time_model == 2) {
              this.form.time_obj = {}
              console.log('this.chineClockList',this.chineClockList)
              console.log('this.form.time_type', this.form.time_type)
              console.log('this.form.time_model', this.form.time_model)
              console.log('this.form.time_obj',this.form.time_obj)
              this.chineClockList.forEach(item => {
                if(item.value) this.form.time_obj[item.key] = Number(item.value)
              })
              console.log('this.form.time_obj', this.form.time_obj)
            }


            // 获取商家发布任务的基础佣金价格
            let result = await this.apiGetBasePriceHandel().catch(() => { return false } )
            if(!result) return false
          // }
        }
        // 第三步
        // 第四步
        if (this.active++ === 4) return this.active = 4
      },


      // 提交发布任务数据
      async apiReleaseTaskHandel() {
        let loading = this.$loading({ text: '任务发布中...'})
        const { success, data } = await apiReleaseTask(this.form).catch(() => loading.close())
        loading.close()
        if(!success) return this.active = 3
        this.taskSucStaus = data.data.status
        this.active = 4 
        this.apiGetUserBalanceHandel()
        console.log(data)
      },

      // 点击确认发布
      sureClick() {
        this.apiReleaseTaskHandel()
        console.log('确认发布')
      },

      // 发布后获取余额
      async apiGetUserBalanceHandel() {
        const { success, data } = await apiGetUserBalance()
        if(!success) return
        this.$global.adminUser.principal = data.balance
      },

      // 处理地区限制 得到后台要的 code
      provinceCodeDetail() {
        let arr = []
        this.areaCities.forEach((item) => {
          if(item.checkedCities.length > 0) {
            arr = arr.concat(item.checkedCities)
          }
        })
        console.log(arr)
        return arr
      },
      // 编辑进来需要选上地区限制
      provinceCheckByCode() {
        this.areaCities.forEach(item => {
          // code 数组
          let arr = item.cities.map(city => city.code)
          // 处理选中
          this.form.province_code.forEach(itemTwo => {
            if(arr.includes(itemTwo)) { item.checkedCities.push(itemTwo) }
            if(item.checkedCities.length) item.immediate = true
            if(item.checkedCities.length === item.cities.length) {
              item.checkAll = true
              item.immediate = false
            }
          })
        })
      },
      // 编辑进来需要处理一下24个时刻的数据
      timeObjByEdit() {
        this.chineClockList.forEach(item => {
          for(let key in this.form.time_obj) {
            if(Number(key)===item.key) {
              item.value = this.form.time_obj[key]
            }
          }
        })
      },

      // 获取商家发布任务的基础佣金价格
      async apiGetBasePriceHandel() {
        let obj = {
          type: 1,
          platform_id: this.form.platform_id,
          user_shop_id: this.form.user_shop_id,
          ben_price: this.BenJinMoney
        }
        let loading = this.$loading()
        const { success, data } = await apiGetBasePrice(obj).catch(() => { loading.close(); return false })
        loading.close()
        if(!success) return false
        console.log(data)
        this.BaseMoney = Number(data.base_price)
        this.servicePrice = Number(data.service_price)
        return true
      }
    },
    computed: {
      // // 平台基础服务费
      // servicePrice() {
      //   if(!this.isGetDataAlready) return 0
      //   if(this.form.platform_id == 1) return this.tbServicePrice
      //   if(this.form.platform_id == 2) return this.jdServicePrice
      //   if(this.form.platform_id == 3) return this.pddServicePrice
      //   return 0
      // },
      // 总订单数
      totalTaskNum() {
        if(!this.isGetDataAlready) return 0
        let num = 0
        if(this.form.task_type == 10) {
          num = this.form.search_keyword.reduce((total, prev) => { return Number(prev.num) + total }, 0)
        }else {
          num = this.form.search_keyword.length
        }
        console.log('总订单数', num)
        return num
      },

      // 发布那边24个小时填写的订单数
      timeObjTaskNum() {
        if(!this.isGetDataAlready) return 0
        let num = 0
        let arr = this.chineClockList.filter(item => item.value !== '')
        num = arr.reduce((total, prev) => { return Number(prev.value) + total }, 0)
        return num
      },     
      
      // 本金费用
      BenJinMoney() {
        if(!this.isGetDataAlready) return 0
        let mianTotal = Math.formatFloat(Number(this.form.actual_price) * Number(this.form.buy_num), 2)
        // 附加商品费用
        let addGoodsTotal = 0
        if(this.form.goods.length > 0) {
          addGoodsTotal = this.form.goods.reduce((total, prev) => {
            return Math.formatFloat(total + Math.formatFloat(Number(prev.price) * Number(prev.num), 2), 2)
          }, 0)
        }
        console.log('本金费用', mianTotal + addGoodsTotal)
        return mianTotal + addGoodsTotal
      },

      // 基础佣金
      // BaseMoney() {
      //  if(!this.isGetDataAlready) return 0
      //  let obj = this.platformList.find(item => item.id === this.form.platform_id)
      //  console.log('基础佣金', obj)
      //  if(obj) return Number(obj.price) 
      //  return 0
      // },

      // 增值服务费
      ZengZhiMoney() {
        if(!this.isGetDataAlready) return 0
        // 淘宝
        if(this.form.platform_id === 1) {
          // 包邮费用
          let baoyouMoney = this.form.is_free === 0 ? Number(this.freePrice[0].price) : 0
          console.log('包邮费用', baoyouMoney)
          // 附加商品费用
          let addgoodsMoney = this.form.goods.length * Number(this.addGoodsPrice[0].price)
          console.log('附加商品费用', addgoodsMoney)
          // 任务类型费用
          let tasktypeMoney = Number(this.taskTypeList.find(item => item.id === this.form.task_type).price)
          console.log('任务类型费用', tasktypeMoney)
          // 货比费用
          let huobiMoney = Number(this.huobiList.find(item => item.id === this.form.huobi).price)
          console.log('货比费用', huobiMoney)
          // 浏览副宝贝费用
          let liulanMoney = Number(this.liulanList.find(item => item.id === this.form.liulan).price)
          console.log('浏览副宝贝费用', liulanMoney)
          // 主宝贝浏览费用
          let arr = this.mainLiulanList.filter(item => {
             return this.form.main_liulan.some(item2 => {
               return item2 === item.id
            })
          })
          let mainliulanMoney = arr.reduce((total, prev) => {
            return total + Number(prev.price)
          }, 0)
          console.log('主宝贝浏览费用', mainliulanMoney)

          // 商家审核费用
          let taskPassMoney = this.form.task_pass === 0 ? 0 : Number(this.taskPassPrice[0].price) 
          console.log('商家审核费用', taskPassMoney)

          // 下单时间费用（预售任务才有的）
          let xdsjMoney = 0
          if(this.form.platform_play_type == 2) {
            let xdsjTarget = this.xdsjList.find(item => item.id === this.form.xdsj)
            console.log('xdsjTarget', xdsjTarget)
            if(xdsjTarget) xdsjMoney = Number(xdsjTarget.price)
            console.log('下单时间费用', xdsjMoney)
          }else {
            xdsjMoney = 0
            console.log('下单时间费用', xdsjMoney)
          }
          
          // 加赏佣金费用
          let rewardMoney = Number(this.form.reward)
          console.log('加赏佣金费用', rewardMoney)

          // 开通花呗费用
          let openHuaBeiMoney = this.form.credit_pay === 0 ? 0 : Number(this.creditPayPrice[0].price)
          console.log('开通花呗费用',openHuaBeiMoney)
          // 信誉要求费用
          console.log('computedxinyu', this.form.xinyu)
          console.log(this.xinYuLevelList)
          let xinyuTarget = this.xinYuLevelList.find(item => item.id === this.form.xinyu)
          console.log('xinyuTarget', xinyuTarget)
          let xinyuMoney = 0
          if(xinyuTarget) xinyuMoney = Number(xinyuTarget.price)
          else xinyuMoney = 0
          console.log('信誉要求费用',xinyuMoney)
          // 性别限制
          let sexMoney = this.form.sex === 0 ? 0 : Number(this.sexPrice[0].price)
          console.log('性别限制费用',sexMoney)
          // 年龄限制
          let ageMoney = this.form.age === 0 ? 0 : Number(this.agePrice[0].price)
          console.log('年龄限制费用',ageMoney)
          // 地区限制
          let areasMoney = this.form.province_code.length === 0 ? 0 : Number(this.provincePrice[0].price) 
          console.log('地区费用',areasMoney)

          let total = Math.formatFloat(baoyouMoney + addgoodsMoney + tasktypeMoney + huobiMoney + liulanMoney + mainliulanMoney + taskPassMoney + xdsjMoney + rewardMoney + openHuaBeiMoney + xinyuMoney + sexMoney + ageMoney + areasMoney, 2)
          console.log('总增值服务费',total)
          return total
        } 
        // 是京东
        else if(this.form.platform_id === 2) {
          // 包邮费用
          let baoyouMoney = this.form.is_free === 0 ? Number(this.freePrice[0].price) : 0
          console.log('包邮费用', baoyouMoney)
          // 附加商品费用
          let addgoodsMoney = this.form.goods.length * Number(this.addGoodsPrice[0].price)
          console.log('附加商品费用', addgoodsMoney)
          // 任务类型费用
          let tasktypeMoney = Number(this.taskTypeList.find(item => item.id === this.form.task_type).price)
          console.log('任务类型费用', tasktypeMoney)
          // 货比费用
          let huobiMoney = Number(this.huobiList.find(item => item.id === this.form.huobi).price)
          console.log('货比费用', huobiMoney)
          // 浏览副宝贝费用
          let liulanMoney = Number(this.liulanList.find(item => item.id === this.form.liulan).price)
          console.log('浏览副宝贝费用', liulanMoney)
          // 主宝贝浏览费用
          let arr = this.mainLiulanList.filter(item => {
             return this.form.main_liulan.some(item2 => {
               return item2 === item.id
            })
          })
          let mainliulanMoney = arr.reduce((total, prev) => {
            return total + Number(prev.price)
          }, 0)
          console.log('主宝贝浏览费用', mainliulanMoney)

          // 商家审核费用
          let taskPassMoney = this.form.task_pass === 0 ? 0 : Number(this.taskPassPrice[0].price) 
          console.log('商家审核费用', taskPassMoney)

          // 下单时间费用（预售任务才有的）
          let xdsjMoney = 0
          console.log('this.form.platform_play_type===', this.form.platform_play_type)
          if(this.form.platform_play_type == 2) {
            let xdsjTarget = this.xdsjList.find(item => item.id === this.form.xdsj)
            console.log('xdsjTarget', xdsjTarget)
            if(xdsjTarget) xdsjMoney = Number(xdsjTarget.price)
            console.log('下单时间费用', xdsjMoney)
          }else {
            xdsjMoney = 0
            console.log('下单时间费用', xdsjMoney)
          }

          // 加赏佣金费用
          let rewardMoney = this.totalTaskNum * Number(this.form.reward)
          console.log('加赏佣金费用', rewardMoney)

          // 开通花呗费用
          let openHuaBeiMoney = this.form.credit_pay === 0 ? 0 : Number(this.creditPayPrice[0].price)
          console.log('开通花呗费用',openHuaBeiMoney)
          // 信誉要求费用
          console.log('computedxinyu', this.form.xinyu)
          console.log(this.xinYuLevelList)
          let xinyuTarget = this.xinYuLevelList.find(item => item.id === this.form.xinyu)
          console.log('xinyuTarget', xinyuTarget)
          let xinyuMoney = 0
          if(xinyuTarget) xinyuMoney = Number(xinyuTarget.price)
          else xinyuMoney = 0
          console.log('信誉要求费用',xinyuMoney)
          // 性别限制
          let sexMoney = this.form.sex === 0 ? 0 : Number(this.sexPrice[0].price)
          console.log('性别限制费用',sexMoney)
          // 年龄限制
          let ageMoney = this.form.age === 0 ? 0 : Number(this.agePrice[0].price)
          console.log('年龄限制费用',ageMoney)
          // 地区限制
          let areasMoney = this.form.province_code.length === 0 ? 0 : Number(this.provincePrice[0].price) 
          console.log('地区费用',areasMoney)

          let total = Math.formatFloat(baoyouMoney + addgoodsMoney + tasktypeMoney + huobiMoney + liulanMoney + mainliulanMoney + taskPassMoney + xdsjMoney + rewardMoney + openHuaBeiMoney + xinyuMoney + sexMoney + ageMoney + areasMoney, 2)
          console.log('总增值服务费',total)
          return total
        }else if(this.form.platform_id === 3) {
          // 包邮费用
          let baoyouMoney = this.form.is_free === 0 ? Number(this.freePrice[0].price) : 0
          console.log('包邮费用', baoyouMoney)
          // 附加商品费用
          let addgoodsMoney = this.form.goods.length * Number(this.addGoodsPrice[0].price)
          console.log('附加商品费用', addgoodsMoney)
          // 任务类型费用
          let tasktypeMoney = Number(this.taskTypeList.find(item => item.id === this.form.task_type).price)
          console.log('任务类型费用', tasktypeMoney)
          // 货比费用
          let huobiMoney = Number(this.huobiList.find(item => item.id === this.form.huobi).price)
          console.log('货比费用', huobiMoney)
          // 浏览副宝贝费用
          let liulanMoney = Number(this.liulanList.find(item => item.id === this.form.liulan).price)
          console.log('浏览副宝贝费用', liulanMoney)
          // 主宝贝浏览费用
          let arr = this.mainLiulanList.filter(item => {
             return this.form.main_liulan.some(item2 => {
               return item2 === item.id
            })
          })
          let mainliulanMoney = arr.reduce((total, prev) => {
            return total + Number(prev.price)
          }, 0)
          console.log('主宝贝浏览费用', mainliulanMoney)

          // 商家审核费用
          let taskPassMoney = this.form.task_pass === 0 ? 0 : Number(this.taskPassPrice[0].price) 
          console.log('商家审核费用', taskPassMoney)

          // 下单时间费用（预售任务才有的）
          let xdsjMoney = 0
          console.log('this.form.platform_play_type===', this.form.platform_play_type)
          if(this.form.platform_play_type == 2) {
            let xdsjTarget = this.xdsjList.find(item => item.id === this.form.xdsj)
            console.log('xdsjTarget', xdsjTarget)
            if(xdsjTarget) xdsjMoney = Number(xdsjTarget.price)
            console.log('下单时间费用', xdsjMoney)
          }else {
            xdsjMoney = 0
            console.log('下单时间费用', xdsjMoney)
          }

          // 加赏佣金费用
          let rewardMoney = this.totalTaskNum * Number(this.form.reward)
          console.log('加赏佣金费用', rewardMoney)

          // 开通花呗费用
          let openHuaBeiMoney = this.form.credit_pay === 0 ? 0 : Number(this.creditPayPrice[0].price)
          console.log('开通花呗费用',openHuaBeiMoney)
          // 信誉要求费用
          console.log('computedxinyu', this.form.xinyu)
          console.log(this.xinYuLevelList)
          let xinyuTarget = this.xinYuLevelList.find(item => item.id === this.form.xinyu)
          console.log('xinyuTarget', xinyuTarget)
          let xinyuMoney = 0
          if(xinyuTarget) xinyuMoney = Number(xinyuTarget.price)
          else xinyuMoney = 0
          console.log('信誉要求费用',xinyuMoney)
          // 性别限制
          let sexMoney = this.form.sex === 0 ? 0 : Number(this.sexPrice[0].price)
          console.log('性别限制费用',sexMoney)
          // 年龄限制
          let ageMoney = this.form.age === 0 ? 0 : Number(this.agePrice[0].price)
          console.log('年龄限制费用',ageMoney)
          // 地区限制
          let areasMoney = this.form.province_code.length === 0 ? 0 : Number(this.provincePrice[0].price) 
          console.log('地区费用',areasMoney)

          let total = Math.formatFloat(baoyouMoney + addgoodsMoney + tasktypeMoney + huobiMoney + liulanMoney + mainliulanMoney + taskPassMoney + xdsjMoney + rewardMoney + openHuaBeiMoney + xinyuMoney + sexMoney + ageMoney + areasMoney, 2)
          console.log('总增值服务费',total)
          return total
        }
        return 0
      }
    }
  }
</script>

<style lang="scss">
.v_task_issue_dian_fu {
  padding: 12px;
  color: #606266;
  font-size: 14px;
  line-height: 20px;
  .s-task-issue-step-item-require-text {
    .el-form-item__label {
      position: relative;
      &::before {
        position: absolute;
        content: '必填';
        color: #f56c6c;
        font-size: 14px;
        font-weight: bold;
        transform: translateX(-100%);
        margin-left: -4px;
      }
    }
  }
  .s-task-issue-step-item-require {
    .el-form-item__label {
      position: relative;
      &::before {
        position: absolute;
        content: '*';
        color: #f56c6c;
        font-size: 18px;
        font-weight: bold;
        transform: translateX(-100%);
        margin-left: -4px;
      }
    }
  }
  .s-task-issue-step-title {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0;
  }

  .s-task-issue-step-item {
    background: $white;
    padding: 20px;
    border-radius: 8px;
  }

  .s-task-issue-step-item-label {
    color: #000;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
  }


  .v-task-issue-head {
    background: $white;
    padding: 20px;
    border-radius: 8px;
    .v-task-issue-head-stepbox {
      .el-steps {
        min-width: 750px;
        .el-step {
          .el-step__head {
            &.is-process {
              color:#5b5c5f;
              border-color: #5b5c5f;
            }
            &.is-success {
              color: $blue;
              border-color: $blue;
            }
          }
          .el-step__main {
            .el-step__title {
              &.is-process {
                color: #5b5c5f;
              }
              &.is-success {
                color: $blue;
              }
            }
          }
        }
      }
    }

    .v-task-issue-head-tips {
      margin-top: 20px;
      padding: 10px 20px;
      border-radius: 5px;
      background: #ffefda;
      border: 1px dashed #fbe2ba;
      .iconfont {
        font-size: 16px;
        color: #FF6600;
      }
      .v-task-issue-head-tips-list {
        color: #333;
        font-size: 14px;
        padding-left: 10px;
        line-height: 20px;
      }
    }

    .v-task-issue-head-btn {
      background: $darkBlue;
      color: #fff;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 5px;
      .iconfont {
        font-size: 20px;
      }
    }
  }

  .v-task-issue-bottom {
    margin-top: 20px;
    .el-button {
      margin-right: 30px;
      padding: 10px 40px;
    }
  }
}
</style>