<template>
  <div class="s_task_issue_dian_fu_step_two">
    <el-form :model="form" ref="ruleForm">
      <!-- 第一步 -->
      <h3 class="s-task-issue-step-title">1.试用活动商品信息</h3>
      <div class="s-task-issue-step-item s-task-issue-step-one-item-first">
        <el-row :gutter="50">
          <!-- 商品链接 -->
          <el-col :span="12">
            <el-form-item label="商品链接：" class="s-task-issue-step-item-require" label-width="120px">
              <el-row :gutter="20">
                <el-col :span="16">
                    <el-input v-model="form.url" size="small"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <!-- 商品标题 -->
          <el-col :span="8">
            <el-form-item label="商品标题：" label-width="120px" class="s-task-issue-step-item-require">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-input v-model="form.title" size="small"></el-input>
                </el-col>
              </el-row>
             
            </el-form-item>
          </el-col>
        </el-row>
        

        <!-- 宝贝示例图 -->
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="宝贝示例图：" class="s-task-issue-step-item-require" label-width="120px">
              <div class="s-task-issue-step-upload-box g-flex-align-center g-flex-wrap">
                <!-- 图片 -->
                <ul class="s-task-issue-step-upload-img-list g-flex-align-center">

                  <li class="s-task-issue-step-upload-img-list-item" v-for="(item,index) in form.img" :key="index">
                    <el-image fit="cover" style="width: 100px; height: 100px" :src="item" :preview-src-list="form.img"> </el-image>
                    <i class="iconfont iconcuo" @click="deleteImgItem(item)"></i>
                  </li>
                </ul>
                <!-- 上传按钮 -->
                <div class="s-task-issue-step-upload g-flex-column g-flex-align-center g-flex-justify-center g-pointer" @click="uploadClick(0)" v-show="form.img.length < 3">
                  <i class="iconfont iconshangchuantupian"></i>
                  <span>上传图片</span>
                </div>
                <!-- 提示 -->
                <div class="s-task-issue-step-upload-tips g-flex-column g-padleft15">
                  <p class="s-task-issue-step-upload-tips-item g-lineheight30">1、上传商品主图，确保与搜索页面展示的一致</p>
                  <p class="s-task-issue-step-upload-tips-item g-lineheight30">2、京东搜索主图一定要手动上传确认，方便试客查找</p>
                  <p class="s-task-issue-step-upload-tips-item g-lineheight30">3、最多可上传3张主图，图片格式JPG，JPEG，PNG 500KB以下</p>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 排序方式 -->
        <el-row :gutter="50">
           <el-col :span="20">
            <el-form-item label="搜索排序方式：" label-width="120px">
               <el-radio-group v-model="form.search_sort">
                <el-radio :label="item.value" v-for="(item, index) in tipsObj.sortMethodList" :key="index">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        
        <!-- 筛选条件备注 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="筛选条件备注：" label-width="120px">
              <span class="g-grey">如新品需卡条件请在此说明，出于安全考虑，请勿大量使用</span>
              <el-row :gutter="20">
                 <el-col :span="6">
                    <el-input v-model="form.search_remark" size="small"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 搜索展示价 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="搜索展示价：" class="s-task-issue-step-item-require" label-width="120px">
              <span class="g-tips-color">搜索页面展示的价格，并非试客实际付款价格,<span class="g-grey">务必亲自搜索，确认价格准确</span></span>
              <el-row :gutter="20">
                 <el-col :span="4" class=" g-flex-align-center">
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.search_price" size="small"></el-input>
                    <span class="g-padleft10">元</span>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 淘口令 -->
        <el-row :gutter="50" v-show="form.entrance === '淘口令'">
          <el-col :span="20">
            <el-form-item label="淘口令：" class="s-task-issue-step-item-require" label-width="120px">
              <el-row :gutter="20">
                 <el-col :span="8" class=" g-flex-align-center">
                    <el-input v-model="form.tkl" size="small"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 选择入口为其他的时候要展示链接 -->
         <el-row :gutter="50" v-show="form.entrance === '其他'">
           <el-col :span="24">
            <el-form-item label="进店链接："  class="s-task-issue-step-item-require" label-width="120px">
              <el-row :gutter="20">
                 <el-col :span="8" class="g-padleft0">
                    <el-input type="text" size="small" v-model="form.entrance_store"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 选择入口的时候 展示入口描述 有入口的时候需要展示 -->
        <el-row :gutter="50" v-show="form.entrance">
           <el-col :span="24">
            <el-form-item label="入口描述：" label-width="120px">
              <el-row :gutter="20">
                 <el-col :span="12" class="g-padleft0">
                    <el-input type="textarea" v-model="form.entrance_remark" maxlength="500" show-word-limit placeholder="重要！！！商家必须清楚描述找到试用宝贝的入口和步骤。第一步，第二步，第三步等..." size="small" :autosize="{ minRows: 5, maxRows: 8}"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 步骤截图 -->
        <el-row :gutter="50" v-show="form.entrance">
          <el-col :span="24">
            <el-form-item label="步骤截图：" label-width="130px">
              <p class="g-tips-color">添加搜索步骤图片（可帮试客找到商品，最多可上传5张）。</p>
              <div class="s-task-issue-step-upload-box g-flex-align-center g-flex-wrap">
                <!-- 图片 -->
                <ul class="s-task-issue-step-upload-img-list g-flex-align-center">
                  <li class="s-task-issue-step-upload-img-list-item" v-for="(item,index) in form.entrance_pic" :key="index">
                    <el-image fit="cover" style="width: 100px; height: 100px" :src="item" :preview-src-list="form.entrance_pic"> </el-image>
                    <i class="iconfont iconcuo" @click="deleteStepImgItem(item)"></i>
                  </li>
                </ul>
                <!-- 上传按钮 -->
                <div class="s-task-issue-step-upload g-flex-column g-flex-align-center g-flex-justify-center g-pointer" @click="uploadClick(12)" v-show="form.entrance_pic.length < 6">
                  <i class="iconfont iconshangchuantupian"></i>
                  <span>上传图片</span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>


        <!-- 拍下件数 -->
        <!-- <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="拍下件数：" class="s-task-issue-step-item-require" label-width="120px">
              <span class="g-tips-color">试客需要拍下的件数</span>
              <el-row :gutter="20">
                 <el-col :span="4" class=" g-flex-align-center">
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.buy_num" size="small"></el-input>
                    <span class="g-padleft10">件</span>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 宝贝下单价 -->
        <!-- <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="宝贝下单价：" class="s-task-issue-step-item-require" label-width="120px">
              <span class="g-tips-color">试客拍下付款后的实际金额，<span class="g-red">含附加商品总额越高佣金越高，请以实际计算为准</span></span>
              <el-row :gutter="20">
                 <el-col :span="4" class=" g-flex-align-center">
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.actual_price" size="small"></el-input>
                    <span class="g-padleft10">元</span>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 是否包邮 -->
        <!-- <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="是否包邮：" label-width="120px">
              <el-radio-group v-model="form.is_free">
                <el-radio :label="1">宝贝包邮，无需运费</el-radio>
                <el-radio :label="0">宝贝不包邮，需要额外多垫付{{freePrice[0].price}}元邮费，随货款一起对买手实行多退少补返还！</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 增加附加商品按钮组  -->
        <!-- <el-row>
          <el-col :span="24">
            <div class="s-task-issue-step-btn-box g-padtop20 g-flex-align-center">
              <el-button type="primary" @click="addGoodsClick">增加附加商品</el-button>
              <el-button type="danger" @click="deleteGoodsClick">删除</el-button>
              <span class="g-tips-color g-padleft10">附加商品为进店后在店铺内浏览，与主宝贝一起下单购买，最多可增加6个，<span class="g-red">每增加1个支付增加 {{addGoodsPrice[0].price}} 金</span></span>
            </div>
          </el-col>
        </el-row> -->

        <!-- 附件商品最多6个 id从1开始 -->
        <task-issue-dian-fu-step-two-add-goods v-for="(item, index) in form.goods" :key="index" :goodsItemForm="item" :idNumber="index + 1"/>
      </div>

      <!-- 选择直播直播直播直播直播的时候 需要展示这一步-->
      <h3 class="s-task-issue-step-title" v-show="form.platform_play_type === 8">2.如何找到商品</h3>
      <div class="s-task-issue-step-item s-task-issue-step-one-item-first" v-show="false">
        <!-- 请选择进店平台： -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="请选择进店平台：" label-width="140px">
              <el-row :gutter="20">
                 <el-col :span="20" class=" g-flex-align-center">
                  <el-select v-model="form.zhiboType" placeholder="请选择">
                      <el-option v-for="(item,index) in zhiboList" :key="index" :label="item.name"  :value="item.value"></el-option>
                    </el-select>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 选择找主播方式 -->
        <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="选择找主播方式：" label-width="140px">
               <el-radio-group v-model="form.findZhubo">
                <el-radio :label="item.value" v-for="(item, index) in findZhuboList" :key="index">
                  {{item.name}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 二维码上传 -->
        <el-row :gutter="50" v-show="form.findZhubo === 2">
          <el-col :span="24">
            <el-form-item label="主播二维码：" label-width="130px">
              <div class="s-task-issue-step-upload-box g-flex-align-center g-flex-wrap">
                <!-- 图片 -->
                <ul class="s-task-issue-step-upload-img-list g-flex-align-center" v-show="form.zhuboQRcode">
                  <li class="s-task-issue-step-upload-img-list-item">
                    <el-image fit="cover" style="width: 100px; height: 100px" :src="form.zhuboQRcode" :preview-src-list="[form.zhuboQRcode]"> </el-image>
                    <i class="iconfont iconcuo" @click="deleteQRcodeImgItem"></i>
                  </li>
                </ul>
                <!-- 上传按钮 -->
                <div class="s-task-issue-step-upload g-flex-column g-flex-align-center g-flex-justify-center g-pointer" @click="uploadClick(10)" v-show="!form.zhuboQRcode">
                  <i class="iconfont iconshangchuantupian"></i>
                  <span>上传图片</span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 主播名字或链接  目前平台没用-->
        <el-row :gutter="50"  v-show="form.findZhubo !== 2">
           <el-col :span="24">
            <el-form-item label="主播名字或链接：" label-width="140px">
              <el-row :gutter="20">
                 <el-col :span="12" class=" g-flex-align-center">
                    <el-input v-model="form.zhuboName" placeholder="请输入主播名字或链接" size="small"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 商品所在的序号  目前平台没用-->
        <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="商品所在的序号：" label-width="140px">
              <el-row :gutter="20">
                 <el-col :span="8" class=" g-flex-align-center">
                    <el-input v-model="form.zhuboName" placeholder="请输入商品所在的序号" size="small"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 是否关注主播  目前平台没用 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="是否关注主播：" label-width="140px">
              <span class="g-tips-color"><span class="g-red">关注须额外支付0.6金</span></span>
              <el-row :gutter="20">
                 <el-col :span="20" class=" g-flex-align-center">
                  <el-radio-group v-model="form.guanZhuzhubo">
                      <el-radio :label="1">不关注主播</el-radio>
                      <el-radio :label="2">关注主播</el-radio>
                    </el-radio-group>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 订单留言 目前平台没用-->
        <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="订单留言：" label-width="140px">
              <el-row :gutter="20">
                 <el-col :span="12" class=" g-flex-align-center">
                    <el-input type="textarea" v-model="form.zhuboName" placeholder="订单留言" size="small" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <!-- 第二步步步步步步步 选择任务类型-->
      <h3 class="s-task-issue-step-title">2.选择任务类型和单数</h3>
      <div class="s-task-issue-step-item s-task-issue-step-one-item-second">
        <!-- 选择任务类型 -->
        <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="" label-width="120px" class="s-task-issue-task-radio-group-form-item">
              <el-radio-group v-model="form.task_type" class="s-task-issue-task-radio-group g-flex-column" @change="typeRadioChange">
                  <div class="s-task-issue-task-radio-group-list" v-for="(item, indexParent) in taskTypeList" :key="indexParent">
                    <el-radio :label="item.id">
                      <span> {{item.title}} </span>
                      <span class="g-padleft10 g-grey">
                        ({{item.detail}} 
                        <span class="g-red"> {{item.price === '0.00' ? '免费': item.price + '金/单' }}</span>)
                        <el-tooltip placement="top" v-show="item.remark">
                          <p slot="content">{{item.remark}}</p>
                          <i class="iconfont iconwenhao_huabanfuben g-blue"></i>
                        </el-tooltip>
                      </span>
                     </el-radio>
                     <div class="s-task-issue-task-radio-group-item-content">
                      <div class="s-task-issue-step-type-select-list" v-show="form.task_type === item.id">
                        <task-issue-dianfu-step-two-keyword v-for="(item, index) in form.search_keyword" :key="index" :radioIndex="indexParent" :reviewType="form.task_type" :keywordEntrance="form.entrance" :propIndex="index + 1" :searchKeyWordForm="item"/>
                      </div>

                      <div class="s-task-issue-step-btn-box g-padtop20 g-flex-align-center"  v-show="form.task_type === item.id">
                        <el-button type="primary" @click="addSearchKeyWordClick">增加关键词</el-button>
                        <el-button type="danger" @click="deleteSearchKeyWordClick">删除</el-button>
                        <span class="g-tips-color g-padleft10">请自行检索确保该关键词可搜索到商品，若是长尾关键词请不要过量发布销量任务</span>
                      </div>
                     </div>
                  </div>
                <!-- <el-radio :label="item.id" v-for="(item, indexParent) in taskTypeList" :key="indexParent">
                    <span> {{item.title}} </span>
                    <span class="g-padleft10 g-grey">
                      ({{item.detail}} 
                      <span class="g-red"> {{item.price === '0.00' ? '免费': item.price + '金/单' }}</span>)
                      <el-tooltip placement="top" v-show="item.remark">
                         <p slot="content">{{item.remark}}</p>
                        <i class="iconfont iconwenhao_huabanfuben g-blue"></i>
                      </el-tooltip>
                    </span>

                    <div class="s-task-issue-step-type-select-list" v-show="form.task_type === item.id">
                      <task-issue-dianfu-step-two-keyword v-for="(item, index) in form.search_keyword" :key="index" :radioIndex="indexParent" :reviewType="form.task_type" :keywordEntrance="form.entrance" :propIndex="index + 1" :searchKeyWordForm="item"/>
                    </div>

                    <div class="s-task-issue-step-btn-box g-padtop20 g-flex-align-center"  v-show="form.task_type === item.id">
                      <el-button type="primary" @click="addSearchKeyWordClick">增加关键词</el-button>
                      <el-button type="danger" @click="deleteSearchKeyWordClick">删除</el-button>
                      <span class="g-tips-color g-padleft10">请自行检索确保该关键词可搜索到商品，若是长尾关键词请不要过量发布销量任务</span>
                    </div>
                </el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 详情页验证关键词 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="详情页验证关键词:" class="s-task-issue-step-item-require" label-width="150px">
              <span class="g-tips-color">从商品详情页面选择你的商品属性或某文字作为关键词，关键词最少2个字，最多10个字，不要输入标题符号，空格，或者特殊字符，设置校验关键词可以增加买手在商品页的停留时间。</span>
              <el-row :gutter="20">
                 <el-col :span="6" class=" g-flex-align-center">
                    <el-input v-model="form.detail_keyword" size="small"></el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </div>


      <!-- 第三步步步步步步步 试用活动要求-->
      <h3 class="s-task-issue-step-title">3.试用活动要求货比商品：</h3>
      <div class="s-task-issue-step-item s-task-issue-step-one-item-third">
        <!-- 货比商品 -->
        <!-- <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="货比商品：" label-width="120px">
               <el-radio-group v-model="form.huobi">
                <el-radio :label="item.id" v-for="(item, index) in huobiList" :key="index">
                  {{item.title}}
                  <span v-if="item.price === ''" class="g-red"></span>
                  <span v-else-if="item.price === '0.00'"  class="g-red">(免费)</span>
                  <span v-else  class="g-red">({{item.price}}金/单)</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 浏览副宝贝 -->
        <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="浏览副宝贝：" label-width="120px">
               <el-radio-group v-model="form.liulan">
                <el-radio :label="item.id" v-for="(item, index) in liulanList" :key="index">
                  {{item.title}}
                  <span v-if="item.price === ''" class="g-red"></span>
                  <span v-else-if="item.price == '0.00'"  class="g-red">(免费)</span>
                  <span v-else  class="g-red">({{item.price}}金/单)</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        
        <!-- 浏览主宝贝 -->
        <el-row :gutter="50">
           <el-col :span="24">
            <el-form-item label="主宝贝浏览：" label-width="120px">
               <el-checkbox-group v-model="form.main_liulan">
                  <el-checkbox v-for="(item, index) in mainLiulanList" :key="index" :label="item.id">
                    {{item.title}}
                  <span v-if="item.price === ''" class="g-red"></span>
                  <span v-else-if="item.price == '0.00'"  class="g-red">(免费)</span>
                  <span v-else  class="g-red">({{item.price}}金/单)</span>
                  </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 付款方式 -->
        <!-- <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="付款方式：" label-width="120px">
              <span class="g-tips-color">选择花呗付款权重更高，必需在增值服务中选择花呗账号才可选此项</span>
              <el-row :gutter="20">
                 <el-col :span="20" class=" g-flex-align-center">
                  <el-radio-group v-model="form.pay_type">
                    <el-radio :label="item.value" v-for="(item, index) in payList" :key="index">
                      {{item.name}}
                    </el-radio>
                  </el-radio-group>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 下单时间（预售任务才有的） -->
        <!-- <el-row :gutter="50" v-show="form.platform_play_type === 2">
          <el-col :span="20">
            <el-form-item label="下单时间：" label-width="120px">
              <el-row :gutter="20">
                 <el-col :span="8" class=" g-flex-align-center">
                  <el-select v-model="form.xdsj" placeholder="请选择">
                    <el-option v-for="(item,index) in xdsjList" :key="index" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 活动截图 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="操作中截图：" label-width="120px">
               <el-radio-group v-model="form.screenshot">
                <el-radio :label="item.value" v-for="(item, index) in jietuList" :key="index">
                  {{item.name}}
                  <span v-if="item.tips!= ''" class="g-tips-color">{{item.tips}}</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 确认收货截图 -->
        <!-- <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="确认收货截图：" label-width="120px">
               <el-radio-group v-model="form.screenshot_arrival" :disabled="confirmRecRadioDisable">
                <el-radio :label="item.value" v-for="(item, index) in recJietuList" :key="index">
                  {{item.name}}
                  <span v-if="item.tips!= ''" class="g-tips-color">{{item.tips}}</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row> -->

        <!-- 特殊要求 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="特殊说明：" label-width="120px">
              <span class="g-tips-color">申请试用成功后可见</span>
              <el-row :gutter="20">
                 <el-col :span="14" class=" g-flex-align-center">
                  <el-input type="textarea" maxlength="200" show-word-limit :autosize="{ minRows: 3, maxRows: 6}"  placeholder="如严禁使用村淘，淘金币等，禁止填写已有的功能选项"  v-model="form.explain"> </el-input>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 特殊说明示例图 -->
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="特殊说明示例图：" label-width="130px">
              <div class="s-task-issue-step-upload-box g-flex-align-center g-flex-wrap">
                <!-- 图片 -->
                <ul class="s-task-issue-step-upload-img-list g-flex-align-center">

                  <li class="s-task-issue-step-upload-img-list-item" v-for="(item,index) in form.explain_img" :key="index">
                    <el-image fit="cover" style="width: 100px; height: 100px" :src="item" :preview-src-list="form.explain_img"> </el-image>
                    <i class="iconfont iconcuo" @click="deleteSpecialImgItem(item)"></i>
                  </li>
                </ul>
                <!-- 上传按钮 -->
                <div class="s-task-issue-step-upload g-flex-column g-flex-align-center g-flex-justify-center g-pointer" @click="uploadClick(8)" v-show="form.explain_img.length < 20">
                  <i class="iconfont iconshangchuantupian"></i>
                  <span>上传图片</span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>


      <!-- 第四步步步步步步步 活动参与人群标签与资格-->
      <h3 class="s-task-issue-step-title">4.活动参与人群标签与资格</h3>
      <div class="s-task-issue-step-item s-task-issue-step-one-item-fourth">
        <!-- 商家审号服务 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="商家审号服务:" label-width="120px">
              <span class="g-tips-color">买号接到任务后需要商家确认以后才能操作，如果15分钟内未确认， 自动通过。须 <span class="g-red">额外支付 {{taskPassPrice[0].price}} 金</span> </span>
              <el-row :gutter="20">
                 <el-col :span="20" class="g-flex-align-center">
                    <el-radio-group v-model="form.task_pass">
                      <el-radio :label="item.value" v-for="(item, index) in taskStorePassList" :key="index">
                        {{item.name}}
                      </el-radio>
                    </el-radio-group>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 开通花呗  白条 拼多多不需要-->
        <el-row :gutter="50" v-if="form.platform_id != 3">
          <el-col :span="20">
            <el-form-item :label="tipsObj.openHuabei.title" label-width="120px">
              <span class="g-tips-color">{{tipsObj.openHuabei.tips}}须 <span class="g-red">额外支付{{creditPayPrice[0].price}}金</span></span>
              <el-row :gutter="20">
                 <el-col :span="20" class=" g-flex-align-center">
                  <el-radio-group v-model="form.credit_pay">
                      <el-radio :label="item.value" v-for="(item, index) in openHuaBeiList" :key="index">
                        {{item.name}}
                      </el-radio>
                    </el-radio-group>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 信誉要求 拼多多不需要 -->
        <el-row :gutter="50" v-if="form.platform_id != 3">
          <el-col :span="20">
            <el-form-item label="信誉要求：" label-width="120px">
              <span class="g-grey">选择要求的买家信誉，<span class="g-red">{{tipsObj.xinyuTips.title}}每增加一个支付增加0.5金</span></span>
              <el-row :gutter="20">
                 <el-col :span="20" class=" g-flex-align-center">
                  <el-select v-model="form.xinyu" placeholder="请选择">
                      <el-option v-for="(item,index) in xinYuLevelList" :key="index" :label="item.title" :value="item.id"></el-option>
                    </el-select>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 性别限制 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="性别限制:" label-width="120px">
              <span class="g-tips-color">限制该项将会影响试用申请的完成率。须 <span class="g-red">额外支付{{sexPrice[0].price}}金</span> </span>
              <el-row :gutter="20">
                 <el-col :span="20" class="g-flex-align-center">
                    <el-radio-group v-model="form.sex">
                      <el-radio :label="item.value" v-for="(item, index) in sexList" :key="index">
                        {{item.name}}
                      </el-radio>
                    </el-radio-group>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 年龄限制 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="年龄限制:" label-width="120px">
              <span class="g-tips-color">限制该项将会影响试用申请的完成率。须 <span class="g-red">额外支付{{agePrice[0].price}}金</span> </span>
              <el-row :gutter="20">
                 <el-col :span="20" class="g-flex-align-center">
                    <el-radio-group v-model="form.age">
                      <el-radio :label="item.value" v-for="(item, index) in ageList" :key="index">
                        {{item.name}}
                      </el-radio>
                    </el-radio-group>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 地区限制 -->
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="地区限制:" label-width="120px"  class="area-form-item">
              <span class="g-grey g-lineheight40">以下所选地区 <span class="g-tips-color">不可接该任务。</span>须 <span class="g-red">额外支付{{provincePrice[0].price}}金/单</span> </span>
              <div v-for="(item, index) in areaCities" :key="index" class="s-task-issue-step-area-list g-flex-align-center">
                <el-checkbox class="s-task-issue-step-area-list-area-item" :indeterminate="item.immediate" v-model="item.checkAll" @change="handleCheckAllChange(item)">{{item.area}}</el-checkbox>
                <el-checkbox-group v-model="item.checkedCities" @change="handleCheckedCitiesChange(item)">
                  <el-checkbox v-for="(city,cityIndex) in item.cities" :label="city.code" :key="cityIndex">{{city.name}}</el-checkbox>
                </el-checkbox-group>
              </div>
  
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 店铺申请限制 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="店铺申请限制：" label-width="120px">
              <el-row :gutter="20">
                 <el-col :span="3" class=" g-flex-align-center">
                  <el-select v-model="form.shop_limit" placeholder="请选择">
                    <el-option v-for="(item,index) in storeApplyList" :key="index" :label="item.name"  :value="item.value"></el-option>
                  </el-select>
                 </el-col>
                 <el-col :span="10">
                    <span class="g-red">内禁止申请该店铺的试用商品</span>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>


        <!-- 发布申请限制 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="发布申请限制：" label-width="120px">
              <el-row :gutter="20">
                 <el-col :span="3" class=" g-flex-align-center">
                  <el-select v-model="form.account_limit" placeholder="请选择">
                    <el-option v-for="(item,index) in issueLimitList" :key="index" :label="item.name"  :value="item.value"></el-option>
                  </el-select>
                 </el-col>
                 <el-col :span="10">
                    <span class="g-red">内禁止申请我账号下发布的试用商品</span>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 加赏佣金 -->
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="加赏任务佣金:" label-width="120px">
              <span class="g-tips-color">加赏金额越高，越优先展示给用户 任务没人接或接不完时，建议加赏提升用户积极性，优先接手你的任务</span>
              <el-row :gutter="20">
                 <el-col :span="5" class=" g-flex-align-center">
                   <span class="g-padright15">每单加赏</span>
                    <el-input class="g-flex-1" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.reward" size="small"></el-input>
                   <span class="g-padleft15">元</span>
                 </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <!--  第六步步步步步步步 活动参与人群标签与资格-->
      <h3 class="s-task-issue-step-title">6.试用活动发布方式</h3>
      <div  class="s-task-issue-step-item s-task-issue-step-one-item-sixth">
        <!-- 发布方式 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="发布方式：" label-width="120px">
               <el-radio-group v-model="form.time_type">
                <el-radio :label="item.value" v-for="(item, index) in fabuMethodList" :key="index">
                  {{item.name}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 任务发布时间 -->
        <el-row :gutter="50" v-show="form.time_type === 1">
          <el-col :span="5">
            <el-form-item label="任务发布时间：" label-width="120px">
               <el-date-picker v-model="form.timeVal" :picker-options="pickerOptions" :clearable="false" type="datetime" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>


        <!-- 任务发布间隔 -->
        <el-row :gutter="50" v-show="form.time_type === 1">
          <el-col :span="20">
            <el-form-item label="任务发布间隔：" label-width="120px">
              <span class="g-grey">间隔多长时间发布一个任务</span>
              <div>
               <el-input-number size="mini" v-model="form.time_interval" :min="1" :max="360"></el-input-number>
               <span class="g-grey g-padleft10">分钟</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 选择小时发布展示   需要开始时间 -->
        <el-row :gutter="50" v-show="form.time_type === 2">
          <el-col :span="24">
            <el-form-item label="起始时间：" label-width="120px">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-date-picker v-model="form.startDate" :clearable="false" type="date" :picker-options="pickerOptions" placeholder="选择日期"></el-date-picker>
                </el-col>
                <el-col :span="6">
                    <span class="g-padright15">开始时刻: </span>
                    <el-select v-model="form.startTime" placeholder="请选择" @change="startTimeChange">
                      <el-option v-for="item in clockList" :key="item.value" :label="item.name"  :value="item.value"></el-option>
                    </el-select>
                  </el-col>
                <el-col :span="6">
                    <span class="g-padright15">结束时刻: </span>
                  <el-select v-model="form.endTime" placeholder="请选择" @change="endTimeChange">
                      <el-option v-for="item in clockEndList" :key="item.value" :label="item.name"  :value="item.value"></el-option>
                    </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

      <!-- 选择小时发布时 模式-->
      <el-row :gutter="50" v-show="form.time_type === 2">
        <el-col :span="24">
          <el-form-item label="任务发布模式：" label-width="120px">
            <el-row>
              <el-col :span="20">
                <span class="g-grey">请将任务数量 <span class="g-tips-color">{{totalTaskNum}}</span> 分配到以下各时间段，剩余 <span class="g-tips-color">{{totalTaskNum - timeObjTaskNum}}</span> 待分配</span>
              </el-col>
            </el-row>
             <el-radio-group v-model="form.time_model" @change="timeModelChange">
              <el-radio :label="1">
                平均模式
                <span class="g-grey">(平均模式是单量间隔时间平均放出,当日完成)</span>
              </el-radio>
              <el-radio :label="2">
                自定义
                <span class="g-grey">(自定义模式可以自行指定每小时放出单量,当日完成)</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>


      <!-- 24个时刻列表 -->
       <el-row :gutter="30" class="s-task-issue-step-item-time-list" v-show="form.time_type === 2">
         <el-col :span="22">
           <el-row :gutter="100" class="s-task-issue-step-item-time-list-content">
             <el-col :span="6" v-for="(item, index) in chineClockList" :key="index" class="g-flex g-flex-align-center">
               <span>{{ item.name }}</span>
               <el-input v-model="item.value" :readonly="form.time_model === 1 ? true : false"></el-input>
             </el-col>
           </el-row>
         </el-col>
       </el-row>

        <!-- 任务回收时间 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="任务回收时间：" label-width="120px">
              <span class="g-grey">超过设定时间后无人接将自动回收，并退回活动押金</span>
              <el-row :gutter="20">
                 <el-col :span="20" class="g-flex-align-center">
                  <el-radio-group v-model="form.close_time">
                    <el-radio :label="item.value" v-for="(item, index) in taskTimeList" :key="index">
                      {{item.name}}
                    </el-radio>
                  </el-radio-group>
                 </el-col>
              </el-row>

            </el-form-item>
          </el-col>
        </el-row>

      </div>
    </el-form>
    
    <!-- 宝贝示例图上传组件 -->
    <task-upload :idNumber="0" :uploadAccept="uploadAccept" @emitFailUpload="emitFailUpload" @emitUploadSuccess="emitUploadSuccess"/>

    <!-- 主播二维码 -->
    <task-upload :idNumber="10" :uploadAccept="uploadAccept" @emitFailUpload="emitFailUpload" @emitUploadSuccess="emitQRcodeUploadSuccess"/>

    <!-- 特殊说明示例图 -->
    <task-upload :idNumber="8" :uploadAccept="uploadAccept" @emitFailUpload="emitFailUpload" @emitUploadSuccess="emitSpecialUploadSuccess"/>

    <!-- 步骤截图 -->
    <task-upload :idNumber="12" :uploadAccept="uploadAccept" @emitFailUpload="emitFailUpload" @emitUploadSuccess="emitStepUploadSuccess"/>
  </div>
</template>

<script>
  import { apiGetAverageOrderTime } from '@/utils/api.js'
  import TaskUpload from './TaskUpload.vue'
  import TaskIssueDianFuStepTwoAddGoods from './TaskIssueDianFuStepTwoAddGoods.vue'
  import TaskIssueDianfuStepTwoKeyword from './TaskIssueDianfuStepTwoKeyword.vue'
  export default {
    components: { TaskUpload, TaskIssueDianFuStepTwoAddGoods, TaskIssueDianfuStepTwoKeyword },
    props: {
      // 下单时间
      xdsjList: {
       type: Array,
        default() {
          return []
        } 
      },
      timeObjTaskNum: {
        type: Number,
        default() {
          return 0
        }
      },
      totalTaskNum: {
        type: Number,
        default() {
          return 0
        }
      },
       clockList: {
        type: Array,
        default() {
          return []
        }
      },
      clockEndList: {
        type: Array,
        default() {
          return []
        }
      },
      chineClockList: {
        type: Array,
        default() {
          return []
        }
      },
      addGoodsPrice: {
        type: Array,
        default() {
          return [{ price: ''}]
        }
      },
      freePrice: {
        type: Array,
        default() {
          return [{ price: ''}]
        }
      },
      provincePrice: {
        type: Array,
        default() {
          return [{ price: ''}]
        }
      },
      taskPassPrice: {
        type: Array,
        default() {
          return [{ price: ''}]
        }
      },
      sexPrice: {
        type: Array,
        default() {
          return [{ price: ''}]
        }
      },
      agePrice: {
        type: Array,
        default() {
          return [{ price: ''}]
        }
      },
      creditPayPrice: {
        type: Array,
        default() {
          return [{ price: ''}]
        }
      },
      // 信誉要求等级
      xinYuLevelList: {
        type: Array,
        default() {
          return []
        }
      },
      // 付款方式
      payList: {
        type: Array,
        default() {
          return []
        }
      },
      // 主宝贝浏览 {id: 10, code: "main_liulan", title: "浏览宝贝评价", price: "0.30", remark: ""}
      mainLiulanList: {
        type: Array,
        default() {
          return []
        }
      },
      //  浏览副宝贝  {id: 7, code: "liulan", title: "不浏览", price: "0.00", remark: ""}
      liulanList: {
        type: Array,
        default() {
          return []
        }
      },

      // 货比列表 {id: 4, code: "huobi", title: "不货比", price: "0.00", remark: ""}
      huobiList: {
        type: Array,
        default() {
          return []
        }
      },
      // 省列表
      areaCities: {
        type: Array,
        default() {
          return []
        }
      },

      // 任务类型
      // {id: 1, code: "taskTypeList", title: "普通好评任务", price: "0.00", remark: "默认五星好评，试客随意发挥，不可规定评价内容"}
      taskTypeList: {
        type: Array,
        default() {
          return []
        }
      },

      // 表单
      form: {
        type: Object,
        default() {
          return {
            platform_id: '', //商城id
            platform_play_id: '', // 任务类型id
            platform_play_type: '', //任务类型是啥 0=未知；1=普通垫付；2=预售垫付；3=叠词回访；4=竞品流量劫持；5=高级引流；6=特别垫付；7=开团垫付；8=直播垫付；
            entrance: '', //活动入口
            user_shop_id: '', //店铺id
            url: '', //商品链接
            title: '', //商品标题
            img: [], // // 宝贝示例图 商品示例图，最多3张
            search_price: '',//搜索展示价
            actual_price: '',//产品下单价
            entrance_remark: '', // 入口描述
            entrance_store: '', //进店链接
            tkl: '',  // 淘口令
            entrance_pic: [], //步骤截图
            search_sort: '综合排序',  // 搜索排序方式  综合排序;销量排序;直通车
            search_remark: '', //搜索备注
            is_free: 1, //1-包邮 0-不包邮
            task_type: '',//搜索关键词任务类型
            search_keyword: [ { keyword: '', num: '', img: [], reviews: '', size: '' }], // 搜索关键词列表
            buy_num: '', //拍下件数
            goods: [], // 附加商品列表  { url: '', img: '', price: '', num: '' }
            // 详情页关键字
            detail_keyword: '',
            // 货比
            huobi: '',
            // 浏览副宝贝
            liulan: '',
            // 浏览主宝贝
            main_liulan: [],
            // 支付方式
            pay_type: '',

            // 发布时间的对象
            time_obj: {},
            // 发布方式
            time_type: 1,
            // 任务发布时间
            timeVal: new Date(new Date().getTime()),

            get time_val() {
              return parseInt(this.timeVal.getTime() / 1000) 
            },
            // 任务发布间隔
            time_interval: 5,
            // 加赏佣金
            reward: '',
            // 商家审核 0关闭 1开启
            task_pass: 0,

            // 开始时间
            startDate: new Date(),

            // 传给后台的开始时间
            get start_date() {
              let year = new Date(this.startDate).getFullYear()
              let month = new Date(this.startDate).getMonth() + 1
              let day = new Date(this.startDate).getDate() 
              return `${year}-${month}-${day}`
            },

            
            // 开始时刻
            startTime: -1,
            // 结束时刻
            endTime: -1,
            // 任务回收时间
            close_time: 0,
            // 操作中截图 0 不截图 1 截图
            screenshot: 0,  

            // 确认收货截图  0 不截图 1 截图
            screenshot_arrival: 0,

            // 特殊要求
            explain:'',
            // 特殊要求示例图
            explain_img: [],
            // 信誉要求
            xinyu: 1,
            // 开通花呗 0不需要 1需要
            credit_pay: 0,

            // 性别限制
            sex: 0,

            // 年龄限制
            age: 0,

            // 地区限制
            province_code: [],
            // 店铺申请限制
            shop_limit: 1,
            // 发布申请限制
            account_limit: 0,

            // 下单时间（预售任务才有的）
            xdsj: '',






            link: '',
            // 直播的时候进店类型
            zhiboType: 1,
            // 找到主播的方式
            findZhubo: 1,

            // 主播名字或者链接
            zhuboName: '',
            // 主播的二维码
            zhuboQRcode: '',

            // 步骤截图
            stepImgList: [],

            // 是否关注主播
            guanZhuzhubo: 1,
          }
        }
      }

    },
    data() {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < (Date.now() - 86400000);
          }
        },

        url: require('../../../assets/img/emoji_sprite.png'),
        srcList: [( require('../../../assets/img/emoji_sprite.png'))],
        uploadAccept: 'image/jpeg,image/jpg,image/png,image/PNG,image/JPG,image/JPEG', //上传的限制类型
        
        
        // 直播列表
        zhiboList: [
          {  name: '请选择进店平台', value: 1 },
          {  name: '手机淘宝直播间', value: 2  },
          {  name: '快手直播间', value: 3  },
          {  name: '抖音直播间', value: 4  },
          {  name: '其他直播间', value: 5  },
        ],

        // 选择找主播方式
        findZhuboList: [
          { name: '搜索主播名字', value: 1 },
          { name: '通过分享主播的淘口令', value: 2 },
          { name: '通过主播分享的二维码', value: 3 },
        ],  
        

        // 主宝贝浏览
        seeMainGoodsList: [
          { name: '浏览宝贝评价', value: 1, much: 0.3  },
          { name: '浏览问大家', value: 2, much: 0.3  },
          { name: '推荐', value: 3, much: 0.3  },
          { name: '收藏宝贝', value: 4, much: 0.3  },
          { name: '收藏店铺', value: 5, much: 0.3  },
          { name: '加入购物车', value: 6, much: 0.3 },
          { name: '旺旺咨询', value: 7, much: 0.3  },
        ],

        

        // 活动截图
        jietuList: [
          { name: '不截图', tips: ' (建议不要求截图：买手按照真实购物行为操作，安全度较高)', value: 0},
          { name: '截图', tips: '',  value: 1},
        ],


        // 确认收货截图
        recJietuList: [
          { name: '不截图', tips: '', value: 0},
          { name: '截图', tips: '(买家确认收货时上传确认收货截图和好评截图)',  value: 1},
        ],

        // 开通花呗
        openHuaBeiList: [
          { name: '不需要', value: 0 },
          { name: '需要', value: 1 },
        ],


        // 性别列表
        sexList:[
          { name: '不限制', value: 0 },
          { name: '男号', value: 1 },
          { name: '女号', value: 2 },
        ],

         // 审核列表
        taskStorePassList: [
          { name: '关闭', value: 0 },
          { name: '开启', value: 1 },
        ],

        // 性别列表
        ageList:[
          { name: '不限制', value: 0 },
          { name: '18~25岁', value: 1 },
          { name: '26-35岁', value: 2 },
          { name: '35岁以上', value: 3 },
        ],

        // 店铺申请限制 
        storeApplyList: [
          { name: '1天', value: 1 },
          { name: '7天', value: 7 },
          { name: '15天', value: 15 },
          { name: '30天', value: 30 },
          { name: '90天', value: 90 },
          { name: '180天', value: 180 },
        ],

        // 发布申请限制
        issueLimitList:[
          { name: '不限', value: 0 },
          { name: '7天', value: 7 },
          { name: '15天', value: 15 },
          { name: '30天', value: 30 },
          { name: '90天', value: 90 },
          { name: '180天', value: 180 },
        ],
        // 发布方式
        fabuMethodList: [
          { name: '立即发布', value: 1 },
          { name: '小时发布', value: 2 },
        ],
        // 任务回收时间
        taskTimeList: [
          { name: '2小时', value: 2 },
          { name: '4小时', value: 4 },
          { name: '8小时', value: 8 },
          { name: '12小时', value: 12 },
          { name: '24小时', value: 24 },
          { name: '48小时', value: 48 },
          { name: '当天24点', value: 0 },
        ],
      }
    },
    methods: {
      // 模式变化
      timeModelChange() {
        if(this.form.time_model === 1) {
          this.apiGetAverageOrderTimeHandel()
        }
        return this.chineClockList.forEach(item => item.value = '')
      },

      // 开始时间选择
      startTimeChange() {
        this.chineClockList.forEach(item => item.value = '')
        this.apiGetAverageOrderTimeHandel()
      },
      
      // 结束时间选择
      endTimeChange() {
        this.chineClockList.forEach(item => item.value = '')
        this.apiGetAverageOrderTimeHandel()
      },

      //  平均分配
      async apiGetAverageOrderTimeHandel() {
        if(!this.totalTaskNum) return this.$message.error('请填写任务数量')
        if(this.form.startTime == -1 || this.form.endTime === -1) return
        // if(this.form.startTime === this.form.endTime) return this.$message.error('开始时间不能等于结束时间')
        if(this.form.startTime > this.form.endTime) return this.$message.error('开始时间不能大于结束时间')
        if(this.form.endTime < this.form.startTime) return this.$message.error('结束时间不能小于开始时间')
        let year = new Date(this.form.startDate).getFullYear()
        let month = new Date(this.form.startDate).getMonth() + 1
        let day = new Date(this.form.startDate).getDate()
        let form = {
          date: `${year}-${month}-${day}`,
          start: this.form.startTime,
          end: this.form.endTime,
          nums: this.totalTaskNum
        }
        const { success, data } = await apiGetAverageOrderTime(form)
        if(!success) return
        // data.data 对象{ 1: 2, 2: 4 }
        this.form.time_obj = data.data
        // 把单数赋值上去
        this.chineClockList.forEach((item) => {
          for(let key in this.form.time_obj) {
            if(Number(key)===item.key) {
              item.value = this.form.time_obj[key]
            }
          }
        })
      },

      // 点击上传附加宝贝
      uploadClick(idNumber) {
        this.$nextTick(() => {
         document.getElementById('s-task-liu-lan-upload'+ idNumber).click()
        })
      },
      // 删除图片
      deleteImgItem(item) {
        let index = this.form.img.indexOf(item)
        this.form.img.splice(index, 1)
      },
      // 上传失败
      emitFailUpload() {
        this.$message.error('上传失败,请重试')
      },
      // 上传成功
      emitUploadSuccess(obj) {
        this.form.img.push(obj.url)
        console.log('上传成功', obj)
      },
      // 添加附加商品
      addGoodsClick() {
        if(this.form.goods.length >= 6) return this.$message.warning('附加商品最多可增加6个')
        this.form.goods.push({ url: '', img: '', price: '', num: '' })
      },
      // 删除附加商品
      deleteGoodsClick() {
        if(this.form.goods.length === 0) return
        this.form.goods.splice(this.form.goods.length -1, 1)
      },
      // 增加关键词点击
      addSearchKeyWordClick() {
        console.log('增加关键词')
        if(this.form.task_type != 10) return this.form.search_keyword.push({ keyword: '', num: 1, img: [], reviews: '', size: '' })
        this.form.search_keyword.push({ keyword: '', num: '', img: [], reviews: '', size: '' })
      },
      //删除关键词点击
      deleteSearchKeyWordClick() {
        console.log('删除关键词')
        if(this.form.search_keyword.length === 1) return
        this.form.search_keyword.splice(this.form.search_keyword.length -1, 1)
      },
      //监听任务类型radio变化
      typeRadioChange() {
        if(this.form.task_type == 11 || this.form.task_type == 12) this.form.screenshot_arrival = 1
        else this.form.screenshot_arrival = 0
        if(this.form.task_type != 10) return this.form.search_keyword = [{ keyword: '', num: 1, img: [], reviews: '', size: '' }]
        this.form.search_keyword = [{ keyword: '', num: '', img: [], reviews: '', size: '' }]
      },
      // 步骤截图示例图上传成功
      emitStepUploadSuccess(obj) {
        this.form.entrance_pic.push(obj.url)
      },
      // 删除步骤截图
      deleteStepImgItem(item) {
        let index = this.form.entrance_pic.indexOf(item)
        this.form.entrance_pic.splice(index, 1)
      },
      // 特殊说明示例图上传成功
      emitSpecialUploadSuccess(obj) {
        this.form.explain_img.push(obj.url)
      },
      // 删除特殊说明示例图
      deleteSpecialImgItem(item) {
        let index = this.form.explain_img.indexOf(item)
        this.form.explain_img.splice(index, 1)
      },

      // 删除主播二维码
      deleteQRcodeImgItem() {
        this.form.zhuboQRcode = ''
      },
      // 主播二维码上传成功
      emitQRcodeUploadSuccess(obj) {
        this.form.zhuboQRcode = obj.url
      },

      // 全选选中
      handleCheckAllChange(item) {
        console.log('itemxxxxxxxxxxxx', item)
        item.checkedCities = item.checkAll ? item.cities.map(item => item.code) : [];
        item.immediate = false;
      },

      // 单个选
      handleCheckedCitiesChange(item) {
        console.log('citiesxxxxxxxxxxxxxxx', item)
        let checkedCount = item.checkedCities.length;
        item.checkAll = checkedCount === item.cities.length;
        item.immediate = checkedCount > 0 && checkedCount < item.cities.length;
      }
    },
    computed: {
      // 确认收货截图 radio组是否能选
      confirmRecRadioDisable() {
        if(this.form.task_type == 10) return false
        if(this.form.task_type == 11 || this.form.task_type == 12) return true
        return false
      },
      tipsObj() {
        // 淘宝
        if(this.form.platform_id === 1) {
          return {
            // 排序方式
            sortMethodList: [
              { name: '综合排序', value: '综合排序' },
              { name: '销量排序', value: '销量排序' },
              { name: '直通车', value: '直通车' },
            ],
            xinyuTips: {
              title: '三心以上(含)',
            },
            openHuabei: {
              title: '开通花呗：',
              tips: '指定优质花呗号试客！效果非常好。'
            },
          }
        }
        // 京东
        else if(this.form.platform_id ===2) {
          return {
            // 排序方式
            sortMethodList: [
              { name: '综合排序', value: '综合排序' },
              { name: '销量排序', value: '销量排序' },
              { name: '京东快车', value: '京东快车' },
            ],
            xinyuTips: {
              title: '铜牌会员(含)'
            },
            openHuabei: {
              title: '开通PLUS会员：',
              tips: '指已开通PLUS会员用户可接该任务，开通了PLUS会员的买号在京东内部被认为是优质的账号，用这些账号很安全，权重高。'
            }
          }
        }else {
          return {
            // 排序方式
            sortMethodList: [
              { name: '综合排序', value: '综合排序' },
              { name: '销量排序', value: '销量排序' },
              { name: '直通车', value: '直通车' },
            ],
            xinyuTips: {
              title: ''
            },
            openHuabei: {
              title: '',
              tips: ''
            }
          }
        }
      }
    },
  }
</script>

<style lang="scss">
.s_task_issue_dian_fu_step_two {
  .el-form {
    .s-task-issue-step-item {
      .el-form-item {
        .el-form-item__label {
          font-weight: bold;
        }
        &.s-task-issue-step-form-item-topbot {
          .el-form-item__content {
            margin-left: 0!important;
          }
        }

        .s-task-issue-step-upload-box {
          .s-task-issue-step-upload-img-list {
            .s-task-issue-step-upload-img-list-item {
              position: relative;
              border: 1px solid #e6e6e6;
              width: 102px;
              height: 102px;
              margin: 0 10px;
              .iconfont {
                font-size: 20px;
                line-height: 20px;
                background: rgba($color: #000000, $alpha: 0.5);
                position: absolute;
                top: 0;
                right: 0;
                color: $white;
              }
            }
          }
          .s-task-issue-step-upload {
            width: 102px;
            height: 102px;
            border-radius: 5px;
            border: 1px solid #e6e6e6;
            margin-left: 10px;
            .iconfont {
              font-size: 30px;
            }
          }
        }
      }
      .s-task-issue-step-btn-box {
        margin-bottom: 20px;
      }
    }

    .s-task-issue-step-one-item-second {
      .s-task-issue-task-radio-group-form-item {
        .el-form-item__content {
          margin-left: 10px!important;
        }
        .s-task-issue-task-radio-group {
          width: 100%;
          .s-task-issue-task-radio-group-list {
            .el-radio {
              width: 100%;
              margin-top: 20px;   
            }
            &:nth-of-type(1) {
              .el-radio {
                margin-top: 0;
              }
            }
            .iconwenhao_huabanfuben {
              font-size: 22px;
              margin-left: 20px;
            }
            .s-task-issue-task-radio-group-content {
              align-self:stretch;
            }
          }
        }
      }

    }

    .s-task-issue-step-one-item-fourth {
      .el-form-item {
        &.area-form-item {
          margin-top: 20px;
          .el-form-item__content {
            line-height: 1;
          }
        }
      }
    }
    .s-task-issue-step-one-item-sixth {
      .s-task-issue-step-item-time-list {
        padding-bottom: 30px;
        .s-task-issue-step-item-time-list-content {
          .el-col {
            padding-top: 30px;
            span {
              flex: 2;
              text-align: right;
              padding-right: 20px;
            }
            .el-input {
              flex: 1;
            }
          }
        }
      }
    }

    .s-task-issue-step-area-list {
      margin: 20px 0;
      .s-task-issue-step-area-list-area-item {
        margin-right: 20px;
        margin-left: 20px;
      }
    }

    

  }
  
}
</style>