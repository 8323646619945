<template>
  <div class="s_task_issue_dianfu_steptwo_keyword">
    <el-form :model="searchKeyWordForm" ref="ruleForm">
      <el-row :gutter="50">
        <el-col :span="24">
          <el-row>
            <!-- 搜索关键词 -->
            <el-col :span="7" v-show="keywordEntrance !== '其他' && keywordEntrance !== '淘口令'">
              <el-form-item class="s-task-issue-step-item-require" :label="'搜索关键词' + propIndex + '：'" prop="keyWord" label-width="120px">
                <el-row :gutter="8">
                  <el-col :span="12">
                      <el-input v-model.trim="searchKeyWordForm.keyword" size="small" placeholder="输入搜素关键词"></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <!-- 普通好评 -->
            <!-- 任务数量 -->
            <el-col :span="14" v-if="reviewType == 10">
            <el-form-item class="s-task-issue-step-item-require" :label="'任务数量' + propIndex + '：'" prop="number" label-width="120px">
                <el-col :span="8" class="g-flex-align-center">
                  <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="searchKeyWordForm.num" size="small"></el-input>
                  <span class="g-font-color g-padleft10">单</span>
                </el-col>
              </el-form-item>
            </el-col>

            <!-- 指定文字好评 -->
            <!-- <el-col :span="17" v-if="reviewType == 11">
              <el-form-item class="s-task-issue-step-item-require" label="请设置指定文字好评的内容：" prop="number" label-width="200px">
                <el-row>
                  <el-col :span="16">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }"  :maxlength="200"  show-word-limit placeholder="填写完整的评价内容" v-model="searchKeyWordForm.reviews"></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col> -->


            <!-- 图文好评 -->
            <!-- <el-col :span="17" v-if="reviewType == 12">
              <el-form-item class="s-task-issue-step-item-require" label="上传照片中商品的规格：" prop="number" label-width="200px">
                <el-row>
                  <el-col :span="6">
                    <el-input v-model="searchKeyWordForm.size" size="small" placeholder="如颜色，尺码"></el-input>
                  </el-col>
                  <el-col :span="10">
                    <span class="g-padleft10">规格设置一定要与图片上商品规格保持一致</span>
                  </el-col>
                </el-row>
              </el-form-item>
            
              <el-form-item class="s-task-issue-step-item-require g-martop30" label="请上传晒图：" prop="number" label-width="180px">
                <div class="s-task-issue-dianfu-steptwo-keyword-upload-box g-flex-align-center g-flex-wrap">
                  <ul class="s-task-issue-dianfu-steptwo-keyword-upload-img-list g-flex-align-center">
                    <li class="s-task-issue-dianfu-steptwo-keyword-upload-img-list-item" v-for="(item,index) in searchKeyWordForm.img" :key="index">
                      <el-image fit="cover" style="width: 100px; height: 100px" :src="item" :preview-src-list="searchKeyWordForm.img"> </el-image>
                      <i class="iconfont iconcuo" @click="deleteImgItem(item)"></i>
                    </li>
                  </ul>

                  <div class="s-task-issue-dianfu-steptwo-keyword-upload g-flex-column g-flex-align-center g-flex-justify-center g-pointer" @click.stop="uploadSecondClick(30 + propIndex)" v-show="searchKeyWordForm.img.length < 5">
                    <i class="iconfont iconshangchuantupian"></i>
                    <span>上传图片</span>
                  </div>

                  <div class="s-task-issue-dianfu-steptwo-keyword-upload-tips g-flex-column g-padtop10 g-padleft15">
                    <p class="s-task-issue-dianfu-steptwo-keyword-upload-tips-item g-lineheight30">最多可上传5张图，图片格式JPG，JPEG，PNG 500KB以下</p>
                  </div>
                </div>
              </el-form-item>

              <task-upload v-if="reviewType == 12 && radioIndex == 2" :idNumber="30 + propIndex" :uploadAccept="uploadAccept" @emitFailUpload="emitFailUpload" @emitUploadSuccess="emitUploadSuccess"/>
              
              <el-form-item class="s-task-issue-step-item-require g-martop30" label="请设置指定文字好评的内容：" prop="number" label-width="200px">
                <el-row>
                  <el-col :span="16">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }"  :maxlength="200"  show-word-limit placeholder="填写完整的评价内容" v-model="searchKeyWordForm.reviews"></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
  // import TaskUpload from './TaskUpload.vue'

  export default {
    // components: { TaskUpload },
    props: {
      // 外面选择radio的index 保证上传只有一个
      radioIndex: {
        type: Number,
        default() {
          return 0
        }
      },
      // 类型 是普通好评还是文字好评还是图文好评 10=普通好评任务 11=指定文字好评任务 12=指定图片好评任务
      reviewType: {
        type: Number,
        default() {
          return 0
        }
      },
      // 入口 如果是其他不要展示搜索关键词 并且不是必填项
      keywordEntrance: {
        type: String,
        default() {
          return ''
        }
      },
      propIndex: {
        type: Number,
        default() {
          return 1
        }
      },
      searchKeyWordForm: {
        type: Object,
        default() {
          return {
            keyword: '', 
            num: '', 
            size: '' ,
            img: [], 
            reviews: '', 
          }
        }
      }
    },
    data() {
      return {
        textarea2: '',
        uploadAccept: 'image/jpeg,image/jpg,image/png,image/PNG,image/JPG,image/JPEG',
        form: {
          img: [
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
          ],
        },
        input: '',
        rules: {
          keyword: [
            { required: true, message: '请输入搜索关键词', trigger: 'blur' },
          ],
           num: [
            { required: true, message: '请输入任务数量', trigger: 'blur' },
          ],
        },

      }
    },
    methods: {
      deleteImgItem(item) {
        let index = this.searchKeyWordForm.img.indexOf(item)
        this.searchKeyWordForm.img.splice(index, 1)
      },
      emitUploadSuccess(obj) {
        this.searchKeyWordForm.img.push(obj.url)
      },
      // 上传失败
      emitFailUpload() {
        this.$message.error('上传失败,请重试')
      },
      // 点击上传附加宝贝
      uploadSecondClick(idNumber) {
        this.$nextTick(() => {
         document.getElementById('s-task-upload'+ idNumber).click()
        })
      },
    }
  }
</script>

<style lang="scss">
.s_task_issue_dianfu_steptwo_keyword {
  background: #f1f1f1;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  color: #606266;
  .s-task-issue-dianfu-steptwo-keyword-upload-box {
    .s-task-issue-dianfu-steptwo-keyword-upload-img-list {
      .s-task-issue-dianfu-steptwo-keyword-upload-img-list-item {
        position: relative;
        border: 1px solid #e6e6e6;
        width: 102px;
        height: 102px;
        margin: 0 10px;
        .iconfont {
          font-size: 20px;
          line-height: 20px;
          background: rgba($color: #000000, $alpha: 0.5);
          position: absolute;
          top: 0;
          right: 0;
          color: $white;
        }
      }
    }
    .s-task-issue-dianfu-steptwo-keyword-upload {
      width: 102px;
      height: 102px;
      border-radius: 5px;
      border: 1px solid #e6e6e6;
      margin-left: 10px;
      .iconfont {
        font-size: 30px;
      }
    }
  }
}
</style>